@use '../../variables' as *;

.tk-lettered-avatar {
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary-color;
    overflow: hidden;
    cursor: pointer;
    font-size: $large-font-size;
    min-width: 48px;
    width: 48px;
    height: 48px;
    border-radius: 100rem;

    .tk-loader {
        width: 60%;
    }

    &.has-border {
        border: 2px solid $white;
        box-shadow: $box-shadow;
    }

    &__content {
        width: auto;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1em;
        white-space: nowrap;
        overflow: hidden;
    }

    &.light {
        color: #000;
    }

    &.dark {
        color: #fff;
    }

    &.small {
        min-width: 32px;
        width: 32px;
        height: 32px;
        font-size: $base-font-size;
    }

    &.medium {
        min-width: 48px;
        width: 48px;
        height: 48px;
    }

    &.large {
        min-width: 60px;
        width: 60px;
        height: 60px;
        font-size: 2rem;
    }

    &.xlarge {
        min-width: 90px;
        width: 90px;
        height: 90px;
        font-size: 2.5rem;
    }

    &__container {
        position: relative;

        & .remove {
            position: absolute;
            left: 100%;
        }

        & .edit {
            position: absolute;
            opacity: 0;
            transition: opacity $transition-duration ease;

            &:hover {
                opacity: 1;
                transition: opacity $transition-duration ease;
            }
        }
    }
}