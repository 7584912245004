@use '../../variables' as *;

.tk-custom-btn {
    position: relative;
    color: $primary-color;
    display: flex;
    align-items: center;
    gap: .5rem;
    opacity: 1;
    cursor: pointer;
    padding: .5rem 1rem;
    border: none;
    border-radius: 60rem;
    transition: $transition-duration all ease-in-out;
    font-weight: 600;

    // position the dot if it is used inside of the button
    .blinking-dot {
        position: absolute;
        top: 5px;
        right: 5px;
    }

    svg {
        width: 1rem;
    }

    &:hover {
        transition: $transition-duration all ease-in-out;
        opacity: .7;
    }

    &.green {
        background-color: $light-green-color;
        color: $white;
    }

    &.secondary {
        background-color: $secondary-color;
        color: $white;
    }

    &.primary {
        background-color: $primary-color;
        color: $white;
        opacity: 1;

        &.with-shadow {
            border: 1px solid $light-blue-color;
            box-shadow: $box-shadow-bottom;
        }

        &:hover {
            transition: $transition-duration all ease-in-out;
            background-color: $primary-accent-color;
        }
    }

    &.white {
        background-color: $white;
        color: $white;
        opacity: 1;
        border: 1px solid $dark-gray;

        svg {
            color: $black;
        }
    }

    &.transparent {
        background-color: transparent;

        &.primary {
            color: $primary-color;
        }

        &.secondary {
            color: $secondary-color;
        }

        &:hover {
            transition: $transition-duration all ease-in-out;
            background-color: transparent;
            opacity: .7;
        }
    }


    &.close-sa {
        padding: .4rem;
        border-radius: 11px;

        &:hover {
            transition: $transition-duration all ease-in-out;
        }

        svg {
            width: 2rem;
        }
    }

    &.large {
        width: 100%;
        justify-content: center;
        border-radius: 90px;
    }

    &:disabled {
        opacity: .3 !important;
        cursor: not-allowed;
        pointer-events: none;
    }

    &.danger {
        color: white;
        background-color: $danger2-color;

        &.transparent {
            color: $danger2-color;
            background-color: transparent;
        }
    }

    &.success {
        color: white;
        background-color: $light-green-color;

        &.transparent {
            color: $light-green-color;
            background-color: transparent;
        }
    }

    * {
        font-size: $small-font-size;
    }
}