@use '../../../variables' as *;

$bg-color: #f9f9f9;

.tk-employees-submitted-forms {
    position: relative;

    &__modal-content-view {
        position: relative;

        &__header {
            max-width: 27rem;
        }

        &__form-data {
            border-right: 1px solid $light-gray;
            padding-right: 1rem;
            flex: 1;

            .mobile-input {
                font-size: $small-font-size;
                word-wrap: break-word;
            }

            .tk-input__icon-before {
                padding-left: 0 !important;
            }

            .condition-wrapper {
                * {
                    font-size: $small-font-size;
                }
            }
        }

        &__timeline {
            min-width: 17rem;
            margin-top: auto;
            max-height: 70vh;
            overflow: auto;
        }

        &__group__body {
            max-height: 70vh;
            overflow: auto;
            padding: 1rem;
            background: $bg-color;
            border-radius: 10px;
            padding-top: 0;
            width: $mobile-form-width;
            margin: auto;
        }

        .status-log-timeline-item {
            .circle-tag {
                width: 1.2rem;
                height: 1.2rem;
                padding: 3px;

                svg {
                    width: 100%;
                    height: auto;
                }
            }
        }

        &__footer {
            margin-top: 1rem;
        }

        .mobile-form-display {
            &>div {
                gap: .5rem !important;
            }
        }
    }

}