@use '../../variables' as *;

.tk-transfer {
    position: relative;

    &__controls {
        margin: auto 1rem;

        button svg {
            width: 2rem;
        }
    }

    &__left {
        flex: 1;
    }

    &__right {
        flex: 1;
    }

    &__loading {
        position: absolute;
        bottom: -.8rem;
        transform: translateX(-50%);
        left: 50%;
    }

    &--simple {

        .tk-transfer__left__top,
        .tk-transfer__right__top {
            padding: .5rem 1rem;
            border-radius: 5px;
            background: $primary-lighter-alt-color;

            h6 {
                margin: 0;
            }

            &__inner {
                margin-left: 1rem;
            }
        }

        .tk-transfer__left__content,
        .tk-transfer__right__content {
            background: $background-color;
            padding: 1rem 0;
            border-radius: 5px;
            max-height: 35rem;
            overflow: auto;
            height: 100%;
        }
    }
}