@use '../../variables' as *;

.tk-adv-list {
    position: relative;
    display: flex;
    flex-direction: column;
    width: max-content;
    overflow: hidden;

    &__head {
        position: sticky;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        top: 0;
        z-index: 1;
        background-color: white;

        &__extra {
            padding: 0 1rem;
            min-height: 1.2rem;
        }
    }

    &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-top: .3rem;

        li {

            &:nth-child(odd) {
                background-color: #d9d9d97e;
            }

            &:nth-child(even) {
                background-color: #d9d9d9e4;
            }

        }

    }

    &__item {
        padding: .5rem 1rem;
        min-height: 2.5rem;
        display: flex;
        align-items: center;

        &.has-checkbox {
            display: flex;
            gap: .5rem;
        }

        &.head {
            max-height: 1rem;
        }

        &--group-head {
            position: relative;
            background-color: unset !important;
        }
    }
}