@use '../../../variables' as *;

.user-level {
    position: relative;

    .value {
        position: relative;
        color: $white;
        line-height: 0;

        span {
            margin-top: -1px;
        }
    }

    svg {
        width: 1.2rem;
        color: #6FD479;
    }

    span.level {
        margin-left: .3rem;
    }
}