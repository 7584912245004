.tk-grid-view {
    position: relative;
    overflow: hidden auto;
    height: 100%;

    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        row-gap: 2rem;
    }

    &__item {
        position: relative;
    }
}