@use '../variables' as *;

.tk-company-subscription {
    position: relative;

    &__content {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-left: .5rem;
    }

    &__control {
        display: flex;
        justify-content: space-between;
        padding: 1rem 1.5rem;
    }

    &__search {
        flex: 1;
        max-width: 35rem;
    }

    &__right {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &__modal-content-view {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        flex: 1;

        &__header {
            position: relative;
            background: $primary-color;
            color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;
            padding: .5rem 1rem;

            &>.edit {
                position: absolute;
                right: 1rem;
                top: 8px;
                cursor: pointer;

                svg {
                    width: 1.3rem;
                }

            }
        }

        &__body {
            display: flex;
            flex-direction: column;
            flex: 1;

            &__inner {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                flex: 1;
            }
        }

        &__footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: .5rem;
        }
    }

    &__modal-content-update {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        flex: 1;

        &__inner {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            flex: 1;
        }

        &__bottom {
            display: flex;
            flex-wrap: wrap;
            gap: 1.5rem;

            &>div {
                flex: 1;
            }
        }

        .tk-input {
            overflow: unset;
        }

        &__group {
            &__header {
                margin-bottom: 1rem;
            }
        }
    }
}