@use '../../variables' as *;

.item-list-spacer {
    &__before-extra {
        position: relative;
        min-width: 1.3rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    &__after-extra {
        position: relative;
    }
}