@use '../../variables' as *;

.tk-section-collapse {
    position: relative;
    background-color: $primary-lighter-alt-color;
    border-radius: 10px;

    &__header {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;

        &__arrow {
            position: relative;
            display: flex;
            transition: $transition-duration all ease-in-out;

            &.active {
                transform: rotate(-180deg);
            }
        }
    }

    &__body {
        position: relative;
        display: flex;
        align-items: center;
        padding: 1rem;
        padding-top: 0;
    }
}