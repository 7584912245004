@use '../../variables' as *;

.tk-timepicker-range {
    position: relative;
    display: flex;
    align-items: center;
    border: none;
    border-bottom: 1px solid $light-gray;
    border-radius: 0;
    min-height: 1.7rem;
    width: 100%;

    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: inherit;
        padding: 4px;
        padding-left: 8px;
    }

    &__label {
        color: $fade-gray;
    }

    .tk-input.is-disabled {
        opacity: .5;
    }
}