@use '../../variables' as *;

.tk-card-page {
    display: flex;
    flex-direction: column;

    &.danger {
        .tk-card-page__header {
            background-color: $danger-color;
        }
    }

    &__inner {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        border-radius: $border-radius;
        border-radius: $border-radius;
        height: 100%;
    }

    &__header {
        // height: 50px;
        min-height: 35px;
        background-color: $primary-color;
        color: $white;
        display: flex;
        align-items: center;
        padding: 0 1.5rem;
        display: flex;
        justify-content: space-between;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
    }

    &__content {
        background-color: transparent;
        padding: 1.5rem;
        display: flex;
    }

    // Override Childrens

    // for table pages
    .tk-table-container {
        background-color: $background-color;
        border-radius: $border-radius;
    }
}