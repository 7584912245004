@use '../../variables' as *;

.tk-password-strength {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: .3rem;

    input:disabled {
        opacity: .5;
    }

    &.very-weak {
        .meter.active {
            border-color: $danger-color;
        }
    }

    &.weak {
        .meter.active {
            border-color: $gold-color;
        }
    }

    &.good {
        .meter.active {
            border-color: $dark-green-color;
        }
    }

    &.strong {
        .meter.active {
            border-color: $dark-green-color;
        }
    }

    &__meters {
        display: flex;
        flex-direction: column;
        gap: .3rem;

        &__group {
            position: relative;
            display: flex;
            gap: .3rem;

            .meter {
                flex: 1;
                border: none;
                border-top: 5px solid $lighter-gray;
                border-radius: 30rem;
            }
        }

        &__label {
            display: flex;
            justify-content: flex-end;
        }
    }

}