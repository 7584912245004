@use '../variables' as *;

$widget-item-border-color: #D4E2F7;

.widget {
    width: 100%;

    .tk-card-page {
        position: relative;
    }

    .tk-card-page__inner {
        background-color: $white;
    }

    .tk-input {
        min-width: 100%;
    }
}

.widget-mini-list-container {
    flex: 1;
    overflow-y: auto;

    ul {
        width: 100%;
        list-style-type: none;
        padding: 0 10px 0 0;
        margin: 0;
        height: inherit;

        li {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;
            border: 1px solid $widget-item-border-color;
            margin-bottom: 0.5rem;
            padding: .8rem;
            background-color: $background-color;
            border-radius: 7px;
            transition: background-color $transition-duration ease;
            animation: #{$kf-fadeFromUpToIn} 0.5s forwards;

            &:hover {
                background-color: #e1edff;
            }

            .message {
                width: 75%;
            }

            .createdAt {
                padding-top: 5px;
                font-size: x-small;
                float: right;
            }
        }
    }
}

.widget-login-activities {
    background-color: $white;
    border-radius: $border-radius;
    padding: 1rem;

    &.is-empty {
        .tk-card-page__content {
            position: relative;
            min-height: 18rem;

            .tk-empty {
                margin: 0 !important;
            }
        }
    }

    .tk-input.search,
    .tk-input input[type=search] {
        background-color: $background-color;
        border-color: $widget-item-border-color;
    }

    .tk-card-page__content {
        padding: 0;
        max-height: 40rem;
        background-color: $white;
    }

    .refresh-icon {
        &.refreshing {
            animation: #{$kf-spin} 1s linear infinite;
        }
    }

    .tk-table-container {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }
}

.widget-report-bug {
    button {
        background-color: $background-color;
        border: 1px solid $widget-item-border-color;
        height: auto !important;
    }
}

.widget-attendance-summary {
    &__content {
        display: grid;
        grid-gap: 5px;
        grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
    }

    &__total-employees {
        background-color: #0E3A7D;
        border-radius: 20rem;
        font-size: 12px;
        padding: .3rem .5rem;
    }

    .attendance-summary-item {
        border: 1px solid $widget-item-border-color;
        padding: .5rem;
        border-radius: 7px;
        display: flex;
        flex-direction: column;
        gap: .5rem;
        background-color: $background-color;

        &__header {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }
    }
}