@use '../../variables' as *;

#nprogress {
    .bar {
        background: $primary-color !important;
        height: 5px !important;
    }

    .peg {
        box-shadow: none !important;
        opacity: none !important;
        transform: unset !important;
    }
}

.page-loader {
    &__logo {
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        animation: #{$kf-pulse} 1.5s infinite ease-in-out;
    }
}