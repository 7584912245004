@use '../../variables' as *;

.condition-wrapper {
    position: relative;
    min-height: 2.2rem;

    &>div {
        height: 100%;
    }

    .subtext {
        margin-left: .5rem;
        margin-top: .2rem;
    }

    &.has-warning:not(&.has-error) {
        padding-left: .5rem;
        border-left: 3px solid $warning-color;

        .tk-timepicker-range .tk-input {
            border: 1px solid $warning-color;
        }

        .tk-select-inner__control,
        .tk-select__label {
            border-bottom: 1px solid $warning-color !important;
        }
    }

    &.has-error {
        padding-left: .5rem;
        border-left: 3px solid $danger-color;

        .tk-timepicker-range .tk-input {
            border: 1px solid $danger-color
        }
    }
}

.tk-input {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid $light-gray;
    border-radius: 5px;
    overflow: hidden;
    font-size: 14px;
    min-width: 15rem;
    width: 100%;
    min-height: 1.7rem;

    &.is-disabled {

        .tk-input__icon-before+div,
        .tk-input__icon-after {
            opacity: .5;
        }

        &.search {
            opacity: .7;
        }
    }

    &.has-label {
        &.is-radio {
            .radio {
                margin-left: auto;
            }
        }

        .checkbox {
            margin-left: auto;
        }
    }

    input {
        min-height: inherit;
        width: inherit;
    }

    .has-loader {
        margin-left: auto;
    }

    &.outlined {
        border: none;
        border-bottom: 1px solid $light-gray;
        border-radius: 0;

        input {
            background-color: transparent !important
        }
    }

    &.warning {
        border: 1px solid orange;
    }

    &__upload {
        width: 100%;
        cursor: pointer;

        &.b64 {
            width: max-content;

            .tk-input__upload__file-text {
                display: flex;
                flex-direction: column;
            }

            .tk-input__upload__preview {
                position: relative;
                max-width: 12rem;
                margin-top: .5rem;
            }

            &.has-value {
                .tk-input__upload__file {
                    .left {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                }
            }

            .tk-input__upload__remove {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0.3rem;
                margin-top: .5rem;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                background: #ff8b8b;

                svg {
                    color: "#fa0000";
                    width: 1.3rem;
                }
            }
        }

        .view-icon {
            svg {
                width: 1.4rem;
                height: auto;
            }
        }

        .remove-icon {
            svg {
                color: red;
            }
        }

        svg {
            // min-width: 2rem;
            max-width: 2rem;
            color: $semi-light-gray;
        }

        input {
            opacity: 0;
            position: absolute;
            top: 0;
            cursor: pointer;
        }

        &>div {
            color: $fade-gray;
            text-indent: 8px;
        }

        &__file {
            position: relative;
            display: flex;
            justify-content: space-between;
            margin-top: .5rem;
        }

        .left {
            display: flex;
            align-items: center;
            gap: 1rem;
            background-color: $lightest-gray;
            padding: .5rem;
            flex: 1;
            border-radius: 5px;
            cursor: pointer;

            span {
                color: $semi-light-gray;
                font-size: 12px;
                text-indent: 0;
            }
        }

        .right {
            display: flex;
            align-items: center;
        }

    }

    &__icon-after {
        display: flex;
        align-items: center;
        background-color: $lighter-gray;
        min-height: inherit;
        padding-top: 1px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;

        span {
            line-height: 1px;
            color: $fade-gray;
            font-weight: 500;
        }

        svg {
            height: 100%;
            width: 1.3rem;
            margin-right: 5px;
            color: #768192;
        }
    }

    &.is-focused {
        .tk-input__icon-after {
            background-color: $white;
        }
    }

    &:not(.has-label) {

        &.date,
        &.time {
            background-color: $lighter-gray;
        }

        input {
            background-color: $lighter-gray;

            &:focus {
                background-color: $white;
            }
        }

        &.is-focused {
            background-color: unset;

            .tk-input__icon-after,
            input {
                background-color: unset;
            }
        }

        .tk-input__mobile {
            .tk-input__inner {
                justify-content: flex-start;

                input {
                    width: 100%;
                }
            }

            .tk-select--outlined .tk-select-inner__control {
                padding: 0 !important;
                background-color: #f1f1f1 !important;

                &--is-focused {
                    background-color: white !important;
                }
            }
        }
    }

    &.is-toggle {
        input[type="checkbox"] {
            position: relative;
            appearance: none;
            width: 2.6rem;
            height: 1.3rem;
            background: #ccc;
            border-radius: 50px;
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            cursor: pointer;
            transition: $transition-duration;
            min-height: auto;
            margin-right: .5rem;
        }

        input:checked[type="checkbox"] {
            background: $primary-color;
            // margin-right: .5rem;
        }

        input[type="checkbox"]::after {
            position: absolute;
            content: "";
            width: 1.3rem;
            height: 1.3rem;
            top: 0;
            left: 0;
            background: $white;
            border-radius: 50%;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
            transform: scale(1.1);
            transition: $transition-duration;
        }

        input:checked[type="checkbox"]::after {
            left: 50%;
        }

    }

    &.is-checkbox:not(.has-label) {
        min-width: unset;
        width: max-content;
        height: max-content;
        border: none;
        overflow: unset;
    }

    &__inner {
        position: relative;
        flex: 1;
        text-align: left;
        align-self: stretch;
        display: flex;
        align-items: center;
        max-width: inherit;
        justify-content: flex-end;
    }

    &.has-label {
        border: none;
        border-bottom: 1px solid $light-gray;
        border-radius: 0;

        .tk-input__icon-before {
            padding: 4px;
            padding-left: 8px;
            background-color: unset;
            border-right: unset;
        }

        input {
            text-align: right;
            // font-weight: 600;
            font-weight: 500;
            padding-right: 0;

            &:disabled {
                background: none;
                opacity: .4;
            }
        }

        .tk-input__icon-after {
            background-color: $white;
            padding-left: 8px;
        }
    }

    &__label {
        color: $fade-gray;
    }

    input {
        border: none;
        outline: none !important;
        flex: 1;
        height: 100%;
        padding: 0 8px;
        color: $black;
        background: transparent;
    }

    &.search,
    input[type="search"] {
        background-color: $light-gray;
    }

    input[type="checkbox"] {
        width: 1.3rem;
        height: 1.3rem;
        display: flex;
        margin-left: auto;
        flex: unset;
    }

    &.search {
        border-radius: 25px;
        min-width: 20rem;
        height: 1.7rem;

        svg {
            font-size: 1.2rem;
        }

        .tk-input__icon-before {
            padding: 4px;
            padding-left: 8px;
            background-color: unset;
        }

        input {
            top: 0;
            padding-right: 8px;
        }
    }

    &__icon-before {
        display: flex;
        background-color: $lighter-gray;
        border-right: 1px solid $light-gray;
        min-height: inherit;
        padding: 0 .5rem;
        align-items: center;
        justify-content: center;

        svg {
            padding: 0;
        }
    }

    &.lg {
        .tk-input__icon-before svg {
            padding: 0 6px;
            height: auto;
            min-width: 2rem;
        }
    }

    &__mobile {
        display: flex;
        width: 100%;

        &-code {
            width: auto;
            min-width: 8rem;
        }

        .tk-select>div:first-child {
            width: 100%;
        }

        .tk-select-inner__control {
            border-bottom: none !important;
        }

        input {
            flex: unset;
            width: 7rem;
        }
    }

    &__textarea {
        display: flex;
        width: 100%;
        flex-direction: column;
        height: 100%;

        &>div {
            &:first-child {
                color: $fade-gray;
                text-indent: 8px;
            }
        }

        &__content {
            margin-top: 0.5rem;
            height: 100%;
            display: flex;

            textarea {
                width: 100%;
                margin: 0;
                padding: 0;
                font-size: 13px;
                padding: .5rem;
                height: 100%;
                border: 1px solid $light-gray;
                border-radius: 5px;
            }

            &.rich-text {
                display: flex;
                position: relative;

                strong {
                    color: $black;
                }

                .ql-toolbar {
                    .ql-formats {
                        svg {
                            pointer-events: none;
                        }
                    }
                }
            }
        }

        .ql-toolbar.ql-snow {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }

        .ql-toolbar.ql-snow+.ql-container.ql-snow {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }

    }

    &__date {

        .react-datepicker__month-select,
        .react-datepicker__year-select {
            outline: none;
            border: 1px solid $light-gray;
            padding: 3px;
            height: 1.5rem;
        }
    }

    &.date {
        .react-datepicker-wrapper {
            width: 100%;
        }

        .react-datepicker-popper {
            z-index: 99;
        }

        &.has-label .tk-input__icon-after {
            background-color: transparent;
        }

        // .react-datepicker__navigation--previous,
        // .react-datepicker__navigation--next {
        //     display: none;
        // }

        .react-datepicker__header {
            h2 {
                margin-bottom: .5rem;
            }
        }
    }

    &.time {
        .react-datepicker-popper {
            z-index: 99;
        }

        &.has-label .tk-input__icon-after {
            background-color: transparent;
        }

        &.clearable {
            .time-icon {
                width: 1.2rem;
                margin: auto;
            }
        }

        // .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
        //     display: none;
        // }
    }
}