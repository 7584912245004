@use '../../variables' as *;

.collapse-card {
    position: relative;
    background-color: $white;
    border-radius: $border-radius;
    border: 1px solid #c2d9ff;
    overflow: hidden;
    width: 100%;

    &__inner {
        position: relative;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $primary-lighter-alt-color;
        gap: 1rem;
        padding: .3rem .5rem;

        &__arrow {
            opacity: .7;
            transition: opacity $transition-duration;

            &.active {
                opacity: 1;
            }
        }

        &__left {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: .5rem;
        }
    }

    &__body {
        padding: .5rem;

        &.open {
            animation: #{$kf-fadeIn} $transition-duration;
        }
    }
}