@use '../../variables' as *;

.tk-form-section {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__header {
        position: relative;
        background: $primary-color;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        padding: .5rem 1rem;

        &>.edit {
            position: absolute;
            right: 1rem;
            top: 8px;
            cursor: pointer;

            svg {
                width: 1.3rem;
            }

        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        flex: 1;

        &__inner {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            flex: 1;
        }
    }
}