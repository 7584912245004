@use '../../variables' as *;

.tk-checkbox {
    position: relative;
    min-width: 1rem;
    width: 1rem;
    height: 1rem;
    border: 2px solid $primary-color;
    border-radius: 2px;
    cursor: pointer;
    user-select: none;
    overflow: hidden;

    input {
        display: none;
    }

    svg {
        position: absolute;
        width: 1rem !important;
    }

    &.active {
        background-color: $primary-color;
    }

    &.disabled {
        pointer-events: none;
        opacity: .5;
    }
}