@use '../variables' as *;

.tk-page-type-01 {
    position: relative;

    .content {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-left: .5rem;
    }

    .control {
        display: flex;
        justify-content: space-between;
        padding: 1rem 1.5rem;
    }

    .search {
        flex: 1;
        max-width: 35rem;
    }
}