@use '../../../variables' as *;

.tk-employee-avatar {
    position: relative;
    padding-top: 3.5rem;
    display: flex;
    flex-direction: column;
    width: 100%;

    .avatar-wrapper {
        width: min-content;
        position: absolute;
        background: $white;
        border-radius: 50%;
        padding: .5rem;
        transform: translateX(-50%);
        left: 50%;
        margin-top: -6.5rem;
    }

    &>div {
        gap: .3rem;
    }

    .name {
        h3 {
            text-align: center;
            font-weight: bolder;
        }
    }
}