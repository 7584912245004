@use '../../variables' as *;

.drawer {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    background-color: $background-color;
    transform: translateX(100%);
    transition: transform 0.2s ease-in-out;
    box-shadow: 0px 0px 20px 1px #cecece;

    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;

    display: flex;
    flex-direction: column;

    &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid #e5e5e5;
        padding: .5rem 1rem;
    }

    &__title {
        flex: 1;
        text-align: center;
    }

    &__content {
        flex: 1;
        padding: 1rem;
    }

    &__footer {
        border-top: 1px solid #e5e5e5;
        padding: .5rem 1rem;
    }

    &__icon {
        svg {
            color: #bbbbbb;
        }
    }

    &.open {
        transform: translateX(0);
    }

    &.close {
        transform: translateX(100%);
    }
}