@use '../../../variables' as *;

.tk-subscription-plan {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;

    &__inner {
        padding: 1rem 2rem;
        flex: 1;
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        overflow: auto;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        font-weight: 500;

        .left {
            display: flex;
            align-items: center;
            gap: 1rem;

            &>span {
                display: flex;
                gap: .5rem;

                &>span:first-child {
                    color: $fade-gray;
                }

            }

            svg {
                width: 1.2rem;
                cursor: pointer;
                opacity: .8;

                &:hover {
                    opacity: 1;
                }
            }
        }

        .right {
            display: flex;
            gap: .5rem;
            color: $danger-color;
        }

    }

    &__item {
        height: 100%;


        &__inner {
            display: flex;
            flex-direction: column;
            height: 100%;
            gap: 1rem;
        }

        &__content {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1rem;
        }

        &__header {
            display: flex;
            flex-direction: column;
            gap: .5rem;
            font-weight: 500;
        }

        &__title {
            text-transform: uppercase;
        }

        &__price {
            display: flex;
            gap: .1rem;
            align-items: flex-end;
            margin: 1rem 0;

            .amount {
                font-weight: 700;
                line-height: 30px;
            }

        }

        &__description {
            .bold {
                font-weight: 800;
            }
        }
    }

    &__control {
        display: flex;
        align-items: center;
        justify-content: center;

        .discount {
            position: absolute;
            top: -55%;
            right: 7px;
        }
    }

    &__items {
        display: flex;
        padding: .5rem;
        justify-content: center;
        align-items: center;

        &>ul {
            display: flex;
            // justify-content: center;
            width: 100%;
            gap: 3rem;
            overflow: auto hidden;
            padding-bottom: 1rem;
            width: calc(100vw - 6.5rem);

            &>li {
                background: $white;
                padding: 2rem;
                border-radius: 15px;
                min-width: 20rem;
                max-width: 20rem;
                max-height: 15rem;
                border: 1px solid $dark-gray;
            }
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &>span {
            text-decoration: underline;
            color: $primary-color;
            cursor: pointer;
        }

        &__features {
            &__group {
                display: flex;
                gap: 1rem;
            }

            &>.title {
                text-align: center;
                margin-bottom: 1rem;
            }
        }

        &__feature {
            display: flex;
            flex-direction: column;
            background: $white;
            padding: 1.5rem;
            border-radius: 5px;
            box-shadow: $box-shadow-bottom;
            max-width: 16rem;

            li {
                padding: 1rem 0;
                text-align: center;

                &:not(:last-of-type) {
                    border-bottom: 1px solid $light-gray;
                }
            }

        }
    }

    &__modal {
        position: relative;
    }

    &--summary {
        position: relative;

        .info-card {
            position: relative;
            background: $white;
            border-radius: 15px;
            padding: 2rem;
            flex-grow: 1;
            min-width: 25rem;
            display: flex;
            flex-direction: column;
            gap: 2rem;

            &__content {
                display: flex;
                gap: 1rem;
            }

            &.summary {

                .left,
                .right {
                    display: flex;
                    flex-direction: column;
                }

                .right {
                    &>div {
                        justify-content: flex-end;
                        align-items: flex-end;
                        gap: .2rem;

                        .money {
                            line-height: 20px;
                        }
                    }

                    &>span {
                        font-size: 12px;
                    }
                }

                .info-card__content {
                    justify-content: space-between;
                }
            }
        }

        .tk-subscription-plan__items {
            display: flex;
            gap: 2rem;
            flex-wrap: wrap;
            align-items: flex-start;
        }

        .group {
            display: flex;
            gap: 2rem;
            flex-direction: column;
            flex-grow: 1;
            max-width: 35rem;

            button {
                text-align: center;
                justify-content: center;
                width: 10rem;
                margin-left: auto;
            }
        }
    }

    &--confirmation {
        position: relative;

        .tk-subscription-plan__items {
            display: flex;
            flex-direction: column;
            margin: auto;
        }

        .confirmation-details {
            display: flex;
            flex-direction: column;
            background: $white;
            border-radius: 15px;
            padding: 2rem;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            min-width: 25rem;
            min-height: 15rem;

            svg.check {
                width: 2.5rem;
                height: auto;
                color: $light-green-color;
            }

            .loading {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                svg {
                    width: 6rem;
                }
            }
        }

        p {
            max-width: 15rem;
            text-align: center;
        }

        .current-plan {
            text-align: center;
            display: flex;
            gap: .5rem;
            flex-direction: column;
        }

    }

    #tk-confirm-temp {
        background-color: $background-color;
        margin: 1.5rem 0;
        padding: 0 .5rem;

        p {
            margin: .3rem 0;
            max-width: unset;
        }
    }
}