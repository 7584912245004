@use '../../variables' as *;

.input-dynamic-list {
    position: relative;

    &__item {
        // display: flex;
        align-items: center;
        border-left: 3px solid $primary-color;
        padding-left: .5rem;

        display: grid;
        grid-template-columns: 1fr 2.5rem;

        &.no-border {
            padding-left: 0;
            border-left: unset
        }

        &.sub-border {
            border-left-color: #ecc800;
            transition: 100ms linear background;

            &:hover {
                background: #e7eef7;
            }
        }

        &.has-error {
            border-left: 3px solid $danger-color;
        }

        &.has-warning:not(&.has-error) {
            border-left: 3px solid $warning-color;
        }

        &.col-layout {
            display: block;
        }

        &.col-layout,
        &.single-layout {
            .input-dynamic-list {
                &__extra {
                    background: unset;
                    margin-left: 0;
                }
            }
        }
    }


    &__content {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &__render {
        flex: 1;
        word-break: break-word;
    }

    &__footer {
        margin-top: 1rem;
    }

    &__extra {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        background: #e5e5e5;
        padding: .5rem;
        margin-left: .5rem;
        border-radius: 5px;
        transition: 100ms linear background;

        &:hover {
            background: #d9d9d9;
        }

    }
}