@use '../../variables' as *;

.accordion {
    position: relative;

    &.secondary {
        .accordion__list__item__title {
            background: #cfcfcf;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: .5rem;
    }

    &__list__item {
        position: relative;
        display: flex;
        flex-direction: column;

        &__title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: $primary-lighter-alt-color;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            color: $black;
            padding: 0 .5rem;
            font-size: $normal-font-size;
            border-bottom: 2px solid #e0e5ef;

            &-value {
                position: relative;
                flex: 1;
            }
        }

        &__controls {
            position: relative;
            display: flex;
            align-items: center;
            margin-left: .5rem;
        }

        &__content {
            position: relative;
            padding: 1rem .5rem;
            background: $background-color;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
}