@use '../../../variables' as *;

.tk-invoice {
    position: relative;

    .pending-icon {
        width: 3rem;
        height: auto;
        color: $primary-color;
    }

    .line {
        width: 100%;
        border-top: 2px dashed $light-gray;

        &.solid {
            border-top-style: solid;
        }
    }

    .instructions {
        gap: .4rem;
        margin-top: .5rem;

        p {
            max-width: max-content;
            margin: 0 !important;
        }
    }
}