@use '../../variables' as *;

.tk-card {
    position: relative;
    background-color: $white;
    padding: 1.5rem;
    border-radius: 10px;
    border: 1px solid #f1f4fa;

    &__title {
        font-size: 2rem;
        font-weight: bold;
    }

    &__inner {
        display: flex;
        flex-direction: column;
        gap: .5rem;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}