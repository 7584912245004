@use '../../variables' as *;

.tk-button-tab {
    position: relative;
    display: flex;
    border: 1px solid #D9D9D9;
    // overflow: hidden;
    border-radius: 10rem;
    box-shadow: $box-shadow-bottom;

    &__inner {
        display: flex;
        padding: .1rem;

        &>ul {
            display: flex;
            font-size: 14px;

            li {
                padding: .5rem 1rem;
                border-radius: 10rem;
                cursor: pointer;
            }
        }
    }

    &--active {
        background-color: $primary-color;
        color: $white;
    }
}