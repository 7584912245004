@use '../variables' as *;

.tk-employees-work-history {
    position: relative;

    &-approvals {
        flex: 1 1 15rem;
    }

    &__modal-content-view {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        flex: 1;

        &__overview {
            margin-top: 3rem;
            gap: 1rem;
            flex-wrap: wrap;

            &.column {
                .tk-employees-work-history-approvals {
                    flex: unset;
                }
            }
        }

        &__top-extra {
            position: relative;
            display: flex;
            flex-direction: column;
            background: $background-color;
            border: 1px solid #c2d9ff;
            border-radius: 14px;
            flex: 1;
            padding: 0 .5rem;
            padding-bottom: 1rem;
            max-height: 30rem;
            height: max-content;

            &__content {
                width: 90%;
                margin: auto;
                min-width: 15rem;
                gap: 1.5rem;
            }

            &__footer {
                width: 90%;
                margin: auto;
            }

            &>div {
                justify-content: space-between;
                height: 100%;
            }

            .note {
                p {
                    margin: 0;
                    margin-top: .3rem;
                }
            }

            .box {
                position: relative;
                background: #E4EDFF;
                border-radius: 10px;
                padding: 1rem;
                padding-top: 1.5rem;
                width: 9rem;
                border: 1px solid #dfe9fd;

                &__value {
                    font-weight: bold;
                }

                &__tag {
                    font-size: $x-small-font-size;
                    position: absolute;
                    top: -15%;
                    right: 10%;
                }
            }

            .top,
            .bottom {
                justify-content: space-around;
            }
        }
    }

    .capsule,
    .shift-box .b1,
    .shift-box .b2 {
        height: 1.3rem;
    }
}

.biometric-approval {
    width: 15rem;
    flex: 1 1 15rem;

    .tk-form-section {
        gap: 0;
    }

    &__content {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        gap: .5rem;
        background-color: $background-color;
        border-radius: 15px;
        overflow: hidden;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border: 1px solid #c2d9ff;
    }

    .tk-form-section__header {
        text-align: center;
    }

    &-item {
        display: flex;
        flex-direction: column;
        gap: .5rem;

        &__inner {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }

        &__sub {
            display: flex;
            flex-direction: column;
            gap: .5rem;

            &-group {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }
}

.time-in {
    width: 15rem;
    min-height: 13rem;
    flex: 1 1 15rem;

    .tk-form-section {
        gap: 0;
    }

    &-photo-wrapper {
        background-color: $background-color;
        border-radius: 15px;
        overflow: hidden;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        padding: .5rem;
        border: 1px solid #c2d9ff;
    }

    img {
        max-width: 9rem;
        border-radius: 15px;
    }
}

.approval-base-actions {
    &__approve-icon {
        span {
            color: $light-green-color;
        }
    }

    &__disapprove-icon {
        span {
            color: $danger-color;
        }
    }
}