.tk-empty {
    display: flex;
    flex-direction: column;
    max-width: 9rem;
    align-items: center;
    justify-content: center;
    gap: .5rem;

    &>svg {
        height: 9rem;
    }
}