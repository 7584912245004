@use '../../variables' as *;

.tk-more {
    position: relative;

    .main-icon {
        cursor: pointer;
    }

    &__items {
        display: flex;
        flex-direction: column;

        li {
            position: relative;
            font-size: $small-font-size;
            cursor: pointer;
            font-weight: 500;
            padding: .2rem;
            // border-radius: 6px;
            border-bottom: $background-alt-color 1px solid;
            padding-bottom: 4px;
            width: 100%;

            &>div {
                align-items: center;
            }
        }
    }
}