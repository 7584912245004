@use '../../../variables' as *;

.tk-employees-settlement {
    position: relative;

    // &__modal-content-view {}


    &__modal-content-create {

        &__group {
            &__body {
                margin-top: 2rem;
            }
        }

        // &__final {}

        // &__others {}
    }
}

.preview-settlement-info {
    .table-list__header {
        padding-right: 0 !important;
    }

    .table-list__item.row.head {
        border-radius: 0;
    }
}

.last-settlement-info,
.preview-settlement-info {
    background-color: $primary-lighter-alt-color;
    border-radius: 10px;
    min-width: 18rem;

    ul {
        display: flex;
        flex-direction: column;
        gap: .3rem;
    }

    &__item {
        display: flex;
        flex-direction: column;
        gap: .3rem;

        &__content {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__subtext {
            margin-left: 1rem;
            display: flex;
            justify-content: space-between;
        }

        p {
            margin-left: 1rem;
            text-align: right;
        }
    }

    .line {
        margin: 1rem 0;
        border-top: 2px solid #d3d3d3;
    }
}