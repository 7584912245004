.shift-box {
    display: flex;
    gap: .2rem;
    width: 100%;

    &.simple {
        flex-direction: column;
        gap: .5rem;

        .b1,
        .b2 {
            background: unset;
            padding: 0;
            border: unset;
        }

        .shift-box__tag {
            position: relative;
            padding: 0;
            top: unset;
            right: unset;
        }
    }

    .b1,
    .b2 {
        position: relative;
        width: 12rem;
        flex: 1;
        background: #E4EDFF;
        padding: 1rem;
        padding-top: 1.5rem;
        border: 1px solid #dfe9fd;
    }

    .b1 {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    .b2 {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    &__tag {
        width: max-content;
        padding: 0.3rem .5rem;
        position: absolute;
        top: -25%;
        right: 0%;
    }
}