@use '../variables' as *;

.tk-designations {
    position: relative;

    &__content {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &__control {
        display: flex;
        justify-content: space-between;
        padding: 1rem 0;
    }

    &__search {
        flex: 1;
        max-width: 35rem;
    }

    &__body {
        margin-top: 1rem;
        height: calc(100vh - 19rem);
    }

    &__item {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: .5rem;
    }

    &__modal-content-view {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        flex: 1;

        &__pob-wrapper {
            padding: 1rem;
            max-width: 50rem;
            max-height: 70vh;
            overflow: hidden auto;
        }

        &__pob {
            display: flex;
            gap: 1rem;
            flex-wrap: wrap;
            align-items: center;

            &-item {
                display: flex;
                flex-direction: column;
                gap: .5rem;
                flex: 1;

                .item.flex {
                    justify-content: space-between;
                    gap: 2rem;

                    .label {
                        font-weight: 600;
                    }
                }
            }
        }
    }

    &__modal-content-update {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        flex: 1;
        padding: 0 3rem;

        &__inner {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            flex: 1;

            &-row {
                display: flex;
                flex-wrap: wrap;
                gap: 2rem;
                justify-content: space-between;

                &>div {
                    flex: 1;
                }
            }
        }

        .tk-input {
            overflow: unset;
        }

        &__group {
            &__header {
                margin-bottom: 1rem;
            }
        }
    }

    &__modal-view-details {
        position: relative;
        padding: 1rem;
        max-height: 20rem;
        overflow: hidden auto;

        li {
            justify-content: space-between;
            flex-wrap: wrap;
            row-gap: 0.5rem;
            padding: 0.5rem 0px;

            &:not(:last-of-type) {
                border-bottom: 2px solid $light-gray;
            }
        }
    }
}