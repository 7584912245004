@use '../../../variables' as *;

.tk-company-announcements {
    position: relative;
}

.tk-company-announcements__modal-content-update {
    .ql-editor {
        height: 30rem;
    }
}

.tk-company-announcements__modal-content-view-html {
    strong {
        color: $black;
    }
}