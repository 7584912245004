@use '../variables' as *;

.tk-roles {
    position: relative;

    &__permissions {
        display: flex;
        flex-wrap: wrap;
        gap: .5rem;
    }

    &__modal-content {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        flex: 1;

        &__header {
            background: $primary-color;
            color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;
            padding: .5rem 1rem;
        }

        &__body {
            display: flex;
            flex-direction: column;
            flex: 1;

            &__inner {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                flex: 1;
            }

            &__bottom {
                display: flex;
                gap: .5rem;
                flex: 1;
            }

            &__sidebar {
                display: flex;
                flex-direction: column;
                border-right: 1px solid $light-gray;
                min-width: 12rem;

                &__header {
                    margin-bottom: 1.5rem;
                }

                ul {
                    display: flex;
                    flex-direction: column;
                    gap: .5rem;
                    flex: 1;
                    margin-left: .5rem;

                    li {
                        padding: .7rem 1rem;
                        border-radius: 20px;
                        cursor: pointer;
                        margin-right: 1rem;

                        &:not(.modal-sidebar-active):hover {
                            transition: $transition-duration ease-in-out background-color;
                            background-color: $background-color;
                            color: $black;
                        }
                    }
                }

                .modal-sidebar-active {
                    background-color: $primary-color;
                    color: $white;
                }
            }

            &__permissions {
                overflow: hidden;
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: 1.5rem;

                ul {
                    display: flex;
                    flex-direction: column;
                    gap: .5rem;

                    li {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                }

                &__header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                &__new {
                    padding-top: 1rem;

                    &>div {
                        display: flex;
                        align-items: center;
                        gap: 1rem;

                        &>div {
                            display: flex;
                            align-items: center;

                            svg {
                                opacity: .8;

                                &:hover {
                                    opacity: 1;
                                }
                            }
                        }
                    }

                    &>p {
                        font-size: 12px;
                        color: $fade-gray;
                        margin: .5rem;
                    }
                }
            }
        }
    }
}