@use '../../variables' as *;

.tk-confirm-alert {
    background: #00000045 !important;
    z-index: 9999 !important;

    &__container {
        background-color: $white;
        border-radius: 10px;
        overflow: hidden;
        max-width: 20rem;
    }

    &__header {
        padding: 1rem;
        background-color: $primary-color;
        color: $white;
    }

    &__content {
        padding: 1rem;
    }

    &__footer {
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
        padding: 1rem;
        padding-top: 0;
        padding-bottom: .5rem;
    }
}