@use '../variables' as *;

.tk-employees-bulk-upload {
    position: relative;

    &__content {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &__body {
        padding-top: 2rem;
        height: 100%;
    }

    summary {
        display: list-item;
    }
}

.tk-bulk-upload-content-container {
    background-color: $background-color;
    padding: 1.5rem;
    border-radius: 15px;
    width: 50vw;

    &__header {
        padding-bottom: 1rem;
    }

    &__footer {
        padding-top: 1.5rem;
    }
}

.duplicate-check-config {
    position: relative;

    .process-btn {
        margin: auto
    }
}

.duplicate-check-skip-updates {
    position: relative;

    &__inner {
        background: #FAFAFA;
        padding: 1rem;
        margin-top: 1rem;
        border-radius: 5px;
        height: 15rem;
        overflow: auto;
    }

    &__list {

        &__item {
            padding: .5rem;
            border-radius: 5px;

            &:nth-child(odd) {
                background: #e1edff;
            }
        }

    }

    .tk-input {
        svg {
            width: unset
        }
    }

}

.tk-bulk-upload {
    height: 100%;
    padding: 0 1.5rem;

    &__excel,
    &__reqfile {
        height: 100%;
        width: 100%;
        display: flex;
        max-width: 60%;
        margin: auto;
    }

    &__excel {
        max-height: calc(-23rem + 100vh);
        align-items: center;
        justify-content: center;
    }

    &__reqfile {
        .instructions {
            ol {
                margin: 0;
                padding-left: 1rem;
                display: flex;
                flex-direction: column;
                gap: .5rem;
            }

            ul {
                li {
                    list-style: lower-alpha;
                    margin-left: 2rem;
                }
            }
        }
    }

    &__check-duplicates {
        position: relative;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: calc(-23rem + 100vh);
        max-width: calc(100vw - 26rem);

        &__content {
            width: 100%;
            height: 100%;

            .manage-duplicates {
                width: 100%;
                padding-top: 1rem;

                details {
                    margin-right: 1rem;

                    summary {
                        margin-bottom: .5rem;
                        cursor: pointer;
                        font-weight: 600;
                        color: $fade-gray2;

                    }

                    p {
                        color: $fade-gray2;
                    }
                }


                p {
                    margin: 0;
                }

                .table-list {
                    margin: .5rem 0;
                    padding-right: 1rem;
                }

                &__footer {
                    margin-top: 2rem;

                    &__content {
                        display: flex;
                        flex-direction: column;
                        gap: .5rem;
                        padding: 1rem;
                        background-color: $background-color;
                        border-radius: 5px;
                        overflow: hidden auto;
                        max-height: 10rem;
                        font-size: 12px;

                        .md-group {
                            display: flex;
                            align-items: center;
                            gap: .5rem;

                            ul {
                                display: flex;
                                gap: .3rem;
                                flex-wrap: wrap;
                            }

                            >span {
                                font-weight: 500;
                                color: $fade-gray2;
                            }
                        }
                    }

                    &__keep {
                        padding: 1rem;
                        background: $secondary-color;
                        border-radius: 7px;
                        color: $white;
                        display: flex;
                        align-items: center;
                        margin-top: 2rem;

                        .tk-checkbox {
                            border-color: $white;
                            background-color: $white;

                            &.active {
                                svg {
                                    color: $secondary-color;
                                }
                            }
                        }
                    }
                }

            }
        }

        >.icon {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: $fade-gray2;
            width: max-content;
            gap: 1rem;
        }

        svg {
            width: 10rem;

            &.lg {
                width: 20rem;
            }
        }
    }

    .bu-custom-error {
        position: relative;

        ol {
            margin: 0;
            margin-top: .5rem;
            padding-left: 1rem;
            display: flex;
            flex-direction: column;
        }

        ul {
            li {
                list-style: lower-alpha;
                margin-left: 2rem;
            }
        }
    }

    &__select-changes-modal-content {
        display: flex;

        &__inner {
            display: flex;
            gap: .1rem;
        }

        &__list {
            &__head {
                width: 100%;
                display: flex;
                align-items: center;
                gap: .5rem;
            }
        }
    }

    &__result {
        // height: 100%;
        height: max-content;
        display: flex;
        flex-direction: column;
        max-width: 50vw;
        align-items: center;
        margin: auto;
        margin-top: 7rem;

        .with-custom-tag {
            &>.tk-tag {
                font-weight: bold;
                display: flex;
                width: max-content;
                height: max-content;
                padding: .5rem;
                border-radius: 10rem;
                min-width: 2rem;
                justify-content: center;
                font-size: 1em;
            }
        }

        .with-custom-btn {
            &>.tk-custom-btn {
                display: flex;
                align-items: center;
                padding: .2rem 1rem;
                width: max-content;
                box-shadow: $box-shadow-minimal;
            }
        }

        &__inner {
            position: relative;
            width: 100%;
            background: $background-color;
            overflow: hidden;
            border-radius: 14px;
        }

        &__header {
            position: relative;
            background-color: $primary-lighter-alt-color;
            padding: 1rem;
            border-radius: 10px;
            box-shadow: $box-shadow-minimal;
            overflow: hidden;

            &>div {
                display: flex;
                gap: 1rem;
                align-items: center;

                h2 {
                    white-space: nowrap;
                    font-weight: 500 !important;
                }
            }
        }

        &__body {
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            padding: 1rem;

            .tk-custom-btn {
                background-color: $background-alt-color;
            }

            .update-records {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                font-weight: 500;

                ul {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                    margin-left: 1rem;

                    li {
                        display: flex;
                        gap: .3rem;
                        width: max-content;
                        align-items: center;

                        &>span {
                            white-space: nowrap;
                        }
                    }
                }
            }

            .file-size {
                display: flex;
                gap: .3rem;
                font-weight: 500;
                align-items: center;
            }
        }

        &__footer {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 1rem;
        }
    }
}