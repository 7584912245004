@use '../../variables' as *;

.tk-popover {
    position: relative;

    &__content {
        position: absolute;
        z-index: 999;
        background-color: #fff;
        border: 1px solid $dark-gray;
        padding: 10px;
        border-radius: $border-radius-md;
        box-shadow: 0 4px 11px hsla(0, 0%, 0%, 0.1);
        opacity: 0;
    }

    &--top {
        bottom: 105%;
        left: 50%;
        transform: translateX(-50%);
        animation: slideTop 200ms ease-in-out forwards;
    }

    &--top-left {
        bottom: 105%;
        left: 0;
        animation: slideTop 200ms ease-in-out forwards;
    }

    &--top-right {
        bottom: 105%;
        right: 0;
        animation: slideTop 200ms ease-in-out forwards;
    }

    &--left {
        top: 50%;
        right: 105%;
        transform: translateY(-50%);
        margin-right: 5px;
        animation: slideLeft 200ms ease-in-out forwards;
    }

    &--right {
        top: 50%;
        left: 105%;
        transform: translateY(-50%);
        margin-left: 5px;
        animation: slideRight 200ms ease-in-out forwards;
    }

    &--bottom {
        top: 105%;
        left: 50%;
        transform: translateX(-50%);
        animation: slideDown 200ms ease-in-out forwards;
    }

    &--bottom-left {
        top: 105%;
        right: 0;
        animation: slideDown 200ms ease-in-out forwards;
    }

    &--bottom-right {
        top: 105%;
        left: 0;
        animation: slideDown 200ms ease-in-out forwards;
    }
}

@keyframes slideTop {
    from {
        transform: translateY(50%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideDown {
    from {
        transform: translateY(-50%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideLeft {
    from {
        transform: translateX(50%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideRight {
    from {
        transform: translateX(-50%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}