@use '../../variables' as *;

.tk-file-upload {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    width: 100%;
    gap: 1rem;

    .info-uploader {
        position: absolute;
        left: 10px;
        top: 10px;
        width: 2rem;

        svg {
            width: 100%;
            height: auto;
        }
    }

    .prev-clear {
        svg {
            width: 1.4rem;
        }
    }


    &>.bottom {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 2rem;

        >.control {
            margin-left: auto;
        }
    }

    &__inner {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: .5rem;
        border: 2px dashed $black;
        padding: 2rem;
        border-radius: 10px;
        width: 100%;

        &.drag-active {
            background-color: $primary-lighter-alt-color;
            border-color: $primary-color;
        }
    }

    .focus {
        color: $primary-color;
        font-weight: bold;
    }

    .description {
        color: $fade-gray2;

        span {
            color: $fade-gray2;
            font-weight: 600;
        }
    }

    .icon {
        svg {
            font-size: 3.5rem;
            color: $primary-color;
        }
    }

    &__preview-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-height: calc(100vh - 40rem);
        width: 100%;
        gap: .2rem;
    }

    &__preview {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        &.error {

            .loading,
            .progress-perc {
                border-color: $danger2-color !important;
            }

            .error-message {
                color: $danger2-color;
                display: flex;
                align-items: center;
                gap: .5rem;
                justify-content: center;
            }
        }

        .prev-clear {
            position: absolute;
            top: 0;
            right: 5px;
            width: min-content;
            padding-right: 0;
        }

        &__item {
            border: 1px solid $dark-gray;
            width: 100%;
            border-radius: 10px;
            padding: 1rem 2rem;
            padding-bottom: .5rem;
            display: flex;
            flex-direction: column;
            gap: .5rem;

            .top {
                display: flex;
                gap: .5rem;

                .left {
                    border: 1px solid $dark-gray;
                    width: 2rem;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .right {
                    display: flex;
                    flex-direction: column;
                    gap: .25rem;

                    .title {
                        font-weight: 600;
                    }

                    .sub {
                        color: $fade-gray2;
                    }
                }

            }

            .bottom {
                display: flex;

                .progress-group {
                    display: flex;
                    align-items: center;
                    gap: .5rem;
                    color: $fade-gray2;
                    flex: 1;

                    .progress-bar {
                        position: relative;
                        flex: 1;

                        .loading {
                            position: absolute;
                            width: 0%;
                            border-top: 6px solid $primary-color;
                            border-radius: 60px;
                            transition: .4s width ease-out;
                        }

                        .placeholder {
                            width: 100%;
                            border-top: 6px solid $dark-gray;
                            border-radius: 60px;
                        }
                    }
                }

                .control {
                    display: flex;
                    margin-left: .5rem;
                    gap: .5rem;

                    button {
                        padding: 0;
                    }
                }
            }
        }
    }
}