@use '../../../variables' as *;

.tk-employee-salary {
    position: relative;

    &__header,
    &__title,
    &__footer {
        margin: 1rem .5em;
    }

    &__footer {
        margin-top: 0;
    }

    &__header {
        margin-bottom: .5rem;
    }

    &__title {
        font-size: $normal-font-size;
        margin-top: 0;
        display: flex;
        flex-direction: column;
        gap: .5rem;
        margin-left: 1rem;

        strong {
            font-weight: 800;
        }
    }

    .tk-table__pagination {
        gap: .5rem;
    }

    .strict-checking {
        margin: 1rem;

        &>span {
            max-width: 24rem;
            display: inline-block;
            word-break: break-word;
            white-space: normal;
            margin-left: .5rem;
            font-style: italic;
        }

        .tk-input {
            overflow: unset;
            border: none;
            width: 1rem;
            min-width: 13rem;
        }

        svg {
            height: max-content;
            margin: 0;
        }
    }

    .total-records {
        order: 1;
    }
}