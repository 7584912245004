@use '../../variables' as *;

.tk-sidebar {
    position: relative;
    display: flex;
    transition: $transition-duration ease-in-out width;
    z-index: 2;
    width: 16rem;
    min-width: 7rem;

    &--compact {
        width: 8rem;
        transition: $transition-duration ease-in-out width;

        .tk-sidebar__item__inner {
            justify-content: center !important;
        }

        .tk-sidebar__footer {

            .tk-auth-layout__sidebar-footer__inner,
            .tk-sidebar__list li {
                justify-content: center !important;

            }

            .tk-sidebar__list li {
                padding-left: 1rem;
            }

            .tk-auth-layout__sidebar-footer__inner {
                padding-left: 1.2rem;
            }
        }
    }

    &__head {
        height: 10rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__content {
        overflow: hidden auto;
        flex: 1;
        display: flex;
        padding-right: .5rem;

        &>ul {
            &>li {
                padding: .5rem;
            }
        }
    }

    &__inner {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 1rem;
        padding-left: 1rem;
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: .5rem;
        width: 100%;
    }

    &__item {
        cursor: pointer;
        min-height: 2.5rem;
        max-height: 2.5rem;
        display: flex;
        align-items: center;
        border-radius: 40px;
        color: $black;

        &-grouped {
            transition: $transition-duration all ease-in-out;

            &.active {
                background-color: $primary-lighter-alt-color;
            }

            &__title {
                display: flex;
                align-items: center;
                gap: .2rem;
                cursor: pointer;

                .icon {
                    transition: $transition-duration all ease-in-out;
                    display: block;
                    position: relative;
                    margin-left: auto;
                    transform: rotate(0deg);

                    &.active {
                        transform: rotate(-180deg);
                    }
                }
            }

            &__list {
                padding: 0;

                &>li:first-child {
                    margin-top: 1rem;
                }
            }
        }

        svg {
            color: $black;
            margin: auto;
            width: 100%;
        }

        &--active {
            background-color: $primary-color;
            color: $white;
            font-weight: bold;

            svg {
                color: $white;
                opacity: 1 !important;
            }

            &:hover {
                background-color: $primary-bright-color;
            }
        }

        &:not(&--active):hover {
            transition: $transition-duration ease-in-out background-color;
            background-color: $white;
            color: $black;
        }

        &--disabled {
            .tk-auth-layout__sidebar-item {
                opacity: .6;
            }

            &:hover {
                background-color: unset !important;
            }
        }

        &__inner {
            padding: 1rem;
            display: flex;
            align-items: center;
            gap: .5rem;

            &>div {
                display: flex;
                align-items: center;
            }
        }
    }

    &__footer {
        padding-top: .5rem;
        border-top: 1px solid $light-gray;
        padding-bottom: 1rem;
        padding-right: .5rem;

        .tk-sidebar__item {
            padding: .5rem 1rem;
            padding-left: 1.5rem;

            &>div {
                padding: 0;
            }
        }
    }
}