@use '../variables' as *;

.tk-employees-work-type {
    position: relative;

    &__modal-view-details {
        position: relative;
        max-height: 20rem;
        overflow: auto;
        max-width: 30rem;
        min-width: 30rem;
        padding: 1rem 2rem;

        li {
            justify-content: space-between;
            gap: 2rem;
            row-gap: 0.5rem;
            padding: 0.5rem 0px;

            &:not(:last-of-type) {
                border-bottom: 2px solid $light-gray;
            }

            .right {
                white-space: nowrap;
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }
    }
}