@use '../variables' as *;

.tk-companies {
    position: relative;

    .new-tag {
        position: absolute;
        top: 1px;
        left: 27px;
    }
}

.tk-companies__modal-content-view__file {
    background: $background-color;
    border-radius: 9px;

    embed {
        height: 80vh;
        width: 70vw;
        position: relative;
        min-width: 20rem;
    }
}

.tk-companies__modal-content-view__contact-details {
    position: relative;
}