@use '../../variables' as *;

.tk-notif-banner {
    position: relative;
}

// 768px; 16px based font size
@media screen and (max-width: 48em) {
    .tk-notif-banner {
        * {
            font-size: 10px !important;
        }
    }
}