@use '../../variables' as *;

.tk-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;

    &.tk-confirm-nav {
        .tk-modal__content__inner>div {
            padding-bottom: .5rem;
        }

        .content {
            padding: 0 1.5rem;
        }
    }

    &__overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        background-color: #000000b5;
    }

    &__content {
        position: relative;
        background-color: white;
        width: 50vw;
        height: 90vh;
        border-radius: $border-radius;
        display: flex;
        flex-direction: column;
        margin: 1rem;

        &__inner {
            flex: 1;
            display: flex;
            flex-direction: column;
            padding: 1.5rem 0;
            overflow: auto;

            &>form {
                display: flex;
                flex-direction: column;
                flex: 1;
                padding: 0 1.5rem;
            }

            &>div {
                padding: 0 1.5rem;
            }

            &.has-header {
                .tk-modal__title {
                    background: $primary-color;
                    color: $white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-top-left-radius: 9px;
                    border-top-right-radius: 9px;
                    padding: .5rem 1rem;
                }

                .tk-modal__close {
                    box-shadow: 0px 1px 2px 0px #00000082;
                }
            }
        }

        form {
            overflow: hidden auto;
        }

        &__body {
            position: relative;
            flex: 1;
            display: flex;
            flex-direction: column;
            margin-top: 2rem;
            overflow: hidden auto;
            padding-right: .5rem;
            padding-bottom: 1rem;
            margin: 0 1rem;
            margin-top: 1rem;
        }

        &__footer {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: .5rem;
            margin-top: auto;
            padding-top: .5rem;
        }
    }

    &__back {
        position: relative;
    }

    &__close {
        position: absolute;
        top: -1.4rem;
        right: 2rem;
        opacity: 1;
        padding: 0.4rem !important;
        z-index: 999;
    }

    &.small {
        .tk-modal {
            &__content {
                width: auto;
                min-width: 15rem;
                min-height: max-content;
                max-height: min-content;
                height: min-content;

                &__inner {
                    padding: 0;
                }

                &__body {
                    margin: 0;
                    padding: 0;
                    overflow: hidden;
                    border-bottom-left-radius: 12px;
                    border-bottom-right-radius: 12px;
                }
            }


            &__title {
                background-color: $primary-color;
                border-top-left-radius: 9px;
                border-top-right-radius: 9px;
                padding: .5rem;
                color: $white;
                text-align: center;
            }

            &__controls {
                button {
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3098039216);
                }
            }
        }
    }
}