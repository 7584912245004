@use '../../variables' as *;

.tk-hr {
    display: flex;
    font-size: 14px;
    gap: 0.5rem;
    color: $black;
    font-weight: 600;

    hr {
        flex: 1;
        border-color: $light-gray;
        border-style: solid;
        border-radius: 5px;
        border-bottom: 0;
        border-width: 1px;
    }
}