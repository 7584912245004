@use '../../variables' as *;

.tk-form-auth-template {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__subtext {
        max-width: 30rem;
        margin: auto;
        color: $fade-gray;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: .5rem;
    }

    &__footer {
        margin-top: 1rem;
        display: flex;
        gap: 1rem;
    }
}