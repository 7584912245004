@use '../../variables' as *;

.tk-company-unauthlayout {
    position: relative;

    .signup {
        overflow: auto;
        padding: 1rem;

        &>.tk-signup-flow {
            align-self: flex-start;
        }

        .tk-layout-with-logo__resp-logo-info {
            justify-content: flex-start;
        }
    }

    .tk-signin {
        margin-top: 2rem;
        max-width: 75%;
    }
}