@use '../variables' as *;

.tk-auth-admin {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: $primary-color;
    display: flex;
    align-items: center;
    justify-content: center;

    &__inner {
        width: 25rem;
    }

    &__title {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .5rem;

        svg {
            height: min-content;
        }
    }

    form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}