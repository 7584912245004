@use '../../variables' as *;

.account-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: .2rem;

    &--centered {
        align-items: center;
    }

    &__sub-account {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: .2rem;

        &__tag {
            background: #e8e8e8;
            padding: .3rem 1rem;
            border-radius: 13px;
            display: flex;
            align-items: center;
            gap: .5rem;
        }
    }

    &__upgrade {
        display: flex;
        align-items: center;
        gap: .5rem;
        font-size: $base-font-size;
        margin-left: auto;

        &__title {
            text-transform: uppercase;
            font-weight: 500;
            font-size: $base-font-size;
            color: gray;
        }

        button {
            font-weight: bold;
            border-radius: 15px;
            width: max-content !important;
            font-size: $small-font-size;
            max-width: 8rem;
            max-height: 2rem;

            svg {
                color: yellow;
            }
        }
    }
}