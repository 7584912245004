@use '../../../variables' as *;

.tk-receipt {
    position: relative;
    border-radius: $border-radius;
    border-color: $background-color;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    box-sizing: border-box;

    .line {
        width: 100%;
        border-top: 2px dashed $light-gray;
    }
}

// we can only see this when the print window appears since its used as a body class for react to print package
.tk-receipt__content {
    padding: 5rem;

    .current-plan {
        text-align: center;
        display: flex;
        gap: 0.5rem;
        flex-direction: column;
    }

    svg.check {
        width: 2.5rem;
        height: auto;
        color: #039D00;
    }

    p {
        margin: 0.3rem 0;
        max-width: unset;
    }

    // footer
    #tk-confirm-temp {
        background-color: #F1F4FA;
        margin: 1.5rem 0;
        padding: 1rem;
        border-radius: 10px;
    }
}