@use '../../variables' as *;

.tk-select {
    position: relative;
    display: flex;

    &--readonly {
        .tk-select-inner--is-disabled {
            opacity: 1;
        }

        .tk-select-inner__indicators {
            display: none;
        }

        .tk-select-inner__control {
            * {
                color: unset;
            }
        }
    }

    .tk-select-inner__option--is-disabled {
        opacity: .5 !important;
    }

    &:not(&--has-label) {
        .tk-select-inner__control {
            border-color: $light-gray !important;

            &--is-focused {
                background-color: unset !important;
                box-shadow: 0 0 0 0px #E2E2E2 !important;
            }
        }
    }

    .custom-option {
        transition: background 60ms;
    }

    .custom-option:hover {
        transition-delay: 60ms;
        background: #deebff;
    }

    .custom-option.custom-select__option--is-focused {
        background: none;
    }

    .custom-option.custom-select__option--is-selected {
        background: #2684FF;
    }

    &__label {
        color: $fade-gray;
        display: flex;
        align-items: center;
        padding-left: 8px;
        font-size: 14px;
        border-bottom: 1px solid $light-gray;
        width: auto !important;
        flex: unset !important;
    }

    &--has-label {
        .tk-select-inner__control {
            &--is-focused {
                box-shadow: unset !important;
            }
        }
    }

    &>div {
        width: 100%;
        flex: 1;
    }

    &--multi {
        &>div {
            width: auto;
            flex: 1;

            &:first-child {
                flex: unset;
                padding-right: .5rem;
            }
        }
    }

    &-inner {
        &__control {
            border-color: $light-gray !important;
            background-color: #efefef !important;
            min-height: 1.7rem !important;
            font-size: 14px !important;
            text-align: left;
            height: 100% !important;

            &--is-focused {
                background-color: unset !important;
                border-color: transparent !important;
                box-shadow: 0 0 0 0px #E2E2E2 !important;
            }
        }

        &__value-container,
        &__dropdown-indicator,
        &__clear-indicator {
            padding: 0 !important;
        }

        &__value-container {
            padding-left: 8px !important;
        }

        &__indicator-separator {
            margin: 5px 0px !important;
        }

        &__menu {
            font-size: 14px !important;
            min-width: 6rem !important;
        }

        &--is-disabled {
            opacity: .5;
        }
    }

    &--outlined {
        .tk-select-inner__control {
            background-color: transparent !important;
            border: none !important;
            border-bottom: 1px solid $light-gray !important;
            border-radius: unset !important;
            padding: 4px 0 !important;
        }

        // .tk-select-inner__value-container {
        //     font-weight: bold !important;
        // }
    }

    &--right {
        .tk-select-inner__single-value {
            text-align: right !important;
            margin-right: 5px !important;
            white-space: normal !important;

            &>div {
                display: flex;
                justify-content: flex-end;
                margin-left: auto;
            }
        }

        .tk-select-inner__input-container {
            justify-content: flex-end;
        }
    }

    &--noborder {

        .tk-select-inner__control,
        .tk-select__label {
            border-bottom: none !important;
        }
    }
}

.virtual-select-list {
    &__item {
        &:hover {
            background: #deebff;
        }

        &--is-selected {
            background: #2684FF;
            color: white;
        }
    }
}