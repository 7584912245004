@use '../../variables' as *;

.tk-view-list {
    position: relative;
    display: flex;
    flex-direction: column;

    li {
        background: linear-gradient($white, #EFEFEF);
        text-align: center;
        padding: 1rem 0;
        font-size: 13px;
        cursor: pointer;

        &:hover {
            background: linear-gradient($white, #ded9d9);
            opacity: 1;
        }
    }

    &__item {
        &:not(&--selected) {
            opacity: .7;
        }

        &--selected {
            background: linear-gradient($white, #ded9d9) !important;
            font-weight: 600;
        }
    }
}