@use '../../variables' as *;

.tk-sub-ended {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    button {
        &>span {
            white-space: pre;
        }
    }

    &__footer {
        margin-top: 1rem;
    }
}