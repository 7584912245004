.tk-logo {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &.shadow {
        img {
            border-radius: 15px;
            box-shadow: 2px 2px 6px 0px rgb(0 0 0 / 35%);
        }
    }
}