@use '../../variables' as *;

$dropdown-bg: #efefef;
$dropdown-svg-fade-color: #d0d0d0;

.tk-dropdown {
    cursor: default;
    display: flex;
    align-items: center;
    font-weight: 400;
    border: 1px solid $light-gray;
    padding: 10px;
    border-radius: 4px;
    height: 100%;
    padding: .3rem .7rem;
    padding-right: 0 !important;
    background-color: $dropdown-bg;
    width: max-content;

    &__divider {
        min-height: 1.3rem;
        height: 60%;
        border: none;
        border-right: 1px solid $dropdown-svg-fade-color;
        margin-left: .5rem;
        margin-right: .1rem;
    }

    &__inner {
        flex: 1;
        font-weight: 600;
    }

    &__prefix {
        font-weight: 600;

        +.tk-dropdown__divider {
            margin-right: .5rem;
        }
    }

    .arrow-icon {
        color: $dropdown-svg-fade-color;
        margin-left: -5px;
    }

    .clear-icon {
        color: hsl(0, 0%, 40%);
        width: .7em;
    }

    &.active {
        background-color: white;

        .arrow-icon {
            color: hsl(0, 0%, 40%);
        }
    }

    &.rounded {
        border-radius: 25rem;
    }

    &.mini {
        padding: 0.2rem .7rem;

        .tk-dropdown__inner,
        .tk-dropdown__prefix {
            font-size: $small-font-size;
        }
    }
}

.tk-dropdown-menu {
    font-weight: 600;
    width: max-content;

    .arrow-icon {
        pointer-events: none;
    }

    &__list {
        width: max-content;
        max-height: 300px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        padding-bottom: 4px;
        padding-top: 4px;
        box-sizing: border-box;

        &--content {
            padding: .5rem;
        }
    }

    &__option {
        position: relative;
        cursor: default;
        display: block;
        font-size: inherit;
        width: 100%;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        background-color: transparent;
        color: inherit;
        padding: 8px 12px;
        box-sizing: border-box;

        &.has-submenu {
            padding-right: 0;
        }

        &--active,
        &:hover {
            background-color: #DEEBFF;
        }

        &__inner {
            display: flex;
            align-items: center;
        }

        &__label {
            flex: 1;
            margin-top: 2px;
        }

        &__extra {
            display: flex;
        }
    }

    &.mini {
        font-size: $small-font-size;
    }
}