@use '../../variables' as *;


.tk-copyright-container {
    width: 23rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.tk-copyright {
    position: relative;
    font-size: 10px;
    gap: .2rem;
    text-align: center;
}