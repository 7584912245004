@use '../../variables' as *;

.tk-waiting-approval {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    &__footer {
        margin-top: 1rem;
    }
}