@use '../variables' as *;

.tk-site {
    position: relative;

    &-card {
        position: relative;
        background: $background-color;
        border-radius: $border-radius;
        width: 15rem;
        border: 1px solid #dde7fb;
        overflow: hidden;

        .hr {
            height: 1px;
            margin: 0 .5rem;
            border-top: 1px solid $primary-color;
        }

        &__more {
            position: absolute;
            top: 10px;
            right: 3px;

            &-content {
                color: $black;
                display: flex;
                flex-direction: column;

                li {
                    display: flex;
                    align-items: center;
                    gap: .5rem;
                    border-bottom: #d1d1d1 1px solid;
                    padding: .2rem 0;
                    cursor: pointer;
                    font-size: 12px;

                    &:hover {
                        opacity: .8;
                    }
                }
            }
        }

        &__header {
            position: relative;
            background-color: $primary-color;
            color: $white;
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;
            height: 3rem;

            &__inner {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: .5rem 1rem;
                gap: .5rem;
                height: 100%;

                .title {
                    max-width: 10rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    line-clamp: 2;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }
        }

        &__upper {
            padding: 1rem;
            display: flex;
            flex-direction: column;
            gap: .5rem;
            height: 8rem;
        }

        &__lower {
            padding: .5rem;
            height: 8rem;
        }

        &__map {
            border: 1px solid $fade-gray-light;
            min-height: 7rem;
            border-radius: 17px;
            width: 14rem;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 7rem;
            max-height: 7rem;

            img {
                height: 100%;
                width: 100%;
            }
        }

        &__content {
            position: relative;
            overflow: hidden;
        }

        &__warning {
            position: absolute;
            z-index: 2;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #00000054;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: 4rem;
            }
        }
    }

    &__view-geozones {
        position: relative;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
        min-height: 2rem;
    }
}