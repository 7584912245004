@use '../../variables' as *;

.image-viewer {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: $background-color;
    width: 90vw;
    height: 80vh;

    &__inner {
        padding: .5rem;
        display: flex;
        overflow: auto;
        cursor: zoom-in;
        width: 100%;
        height: 100%;
        justify-content: center;
    }

    &__controls {
        display: flex;
        justify-content: center;
        align-items: center;
        background: $white;
        padding: .5rem;
        padding-bottom: 0;
    }

    img {
        transform-origin: top center;
        transition: transform 0.3s ease-in-out;
        width: auto;
        height: auto;
    }


    .zoom-control {
        position: relative;
        display: flex;
        gap: .5rem;
        align-items: center;


        svg {
            width: 1.8rem;
            height: auto;
        }

        &>* {
            display: flex;
            align-items: center;
        }

        .tk-input {
            width: min-content;
            min-width: min-content;

            input {
                width: 2rem;
                text-align: center;
                padding: 0;
            }

            &__icon-after {
                padding-right: 3px;
            }
        }
    }
}