@use '../../variables' as *;

.tk-layout-with-logo {
    position: relative;

    &__logo-info {
        position: absolute;
        top: 2rem;
        left: 2rem;
        color: $white;

        &>span {
            font-size: 14px;
            text-transform: uppercase;
        }

        &>h3 {
            letter-spacing: 1px;
        }
    }

    &__inner {
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $primary-color;
    }

    &__left,
    &__right {
        position: relative;
        flex: 1;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__logo {
        display: flex;
        height: 80%;
        width: 100%;
        margin-top: auto;

        img {
            width: 100%;
            max-width: 100%;
            height: auto;
            display: block;
            opacity: 0;
            animation: #{$kf-fadeIn} 0.5s ease-in-out forwards;
        }
    }

    &__right {
        background-color: $white;
        padding: 0 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        overflow: auto;
        justify-content: flex-start;

        &__inner {
            margin: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    &__resp-logo-info {
        margin: 2rem 0;
        display: flex;
        align-items: center;
        gap: 1rem;
        justify-content: center;
        display: none;
        max-width: 75%;
        width: 100%;

        .info {
            span {
                text-transform: uppercase;
                font-weight: 500;
            }
        }
    }
}