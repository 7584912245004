@use '../../variables' as *;

.notification {
    position: relative;

    .mark-as-read-all-icon {
        color: $primary-color;
    }

    &__header {
        padding: 1rem 1.3rem;
        border-bottom: 1px solid $dark-gray;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        &__top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1rem;
        }

        &__actions {
            display: flex;
            gap: .3rem;
            align-items: center;
        }
    }

    &__content {
        font-size: $small-font-size;
        overflow: auto;
        max-height: 75vh;

        &__inner {
            position: relative;
        }
    }

    &__group-item {
        position: relative;
    }

    &-item {
        padding: 0.8rem 1.3rem;
        background-color: $white;

        &__inner {
            display: flex;
            gap: .5rem;
        }

        &__content {
            flex: 1;
            gap: 1rem;
            display: flex;
            flex-direction: column;

            &__inner {
                display: flex;
                justify-content: space-between;
                gap: .5rem;
            }
        }

        &__timestamp {
            display: flex;
            justify-content: space-between;
        }

        &:not(&.notif-title) {
            margin: .2rem;
        }

        &.unread {
            position: relative;
            background-color: $primary-lighter-alt-color;
            border-radius: 4px;
            cursor: pointer;

            &:hover {
                background-color: #edf4ff;
            }
        }

        &.newly-added {
            transition: transform 0.2s ease-out, opacity 0.2s ease-out;
            will-change: transform;
            transform-origin: left;
            animation: appear 0.2s ease-out;
        }

        &.notif-title {
            background-color: $background-color;
        }

        .red-dot {
            transition: transform 0.2s ease-out, opacity 0.2s ease-out;
            will-change: transform;
            animation: appear 0.3s ease-out;
        }

    }
}

.notification-button {
    height: 100%;

    .notification-warning {
        position: absolute;
        color: red !important;
        top: 0;
        right: 0;
        width: 21px;
        opacity: 1;
    }

    .notification-counter {
        position: absolute;
        transform-origin: right;
        transform: translate(50%, -50%);
        z-index: 1;
        transition: transform 0.2s ease-out, opacity 0.2s ease-out;
        will-change: transform;

        &.red-dot {
            animation: appearDotCounter 0.3s ease-out;
        }
    }
}

.notification-template {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: .5rem;

    &__message {
        margin: 0;
    }
}

.notification-action-button {
    padding: .3rem .8rem !important;
    height: max-content !important;

    span {
        font-size: 10px !important;
    }
}

.notification-filter {
    border: 1px solid $dark-gray;
    border-radius: 8px;
    background-color: $background-color;
    overflow: hidden;
    width: max-content;
    cursor: pointer;
    font-size: $small-font-size;

    &--wide {
        position: relative;
        width: 100%;
    }

    &--button {
        position: relative;
        width: 100%;
        padding: .2rem .5rem;
        background-color: $white;

        &>div {
            width: 100%;

            &>div {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: .5rem;
                min-height: 1.5rem;
            }
        }

        .notif-filter-label {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: .5rem;
        }
    }

    .notification-counter {
        &.red-dot {
            width: max-content;
            padding: 2px 3px;
            border: unset;
            min-height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 19px;
            transition: transform 0.2s ease-out, opacity 0.2s ease-out;
            will-change: transform;
            animation: appear 0.3s ease-out;
        }
    }
}

.notification-filter-items {
    display: flex;
    background-color: $background-color;
    gap: 2px;
    overflow: hidden;

    &--menu {
        flex-direction: column;

        .notification-filter-items__item {
            justify-content: space-between;
            min-height: 2.5rem;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .3rem;
        padding: 8px;
        border-radius: 6px;
        flex: 1;
        transition: background-color 200ms linear;
        will-change: background-color;
        cursor: pointer;

        &--active {
            background-color: white;
            border: 1px solid $dark-gray;
        }

        span {
            white-space: nowrap;
        }

        &:not(&--active) {
            &:hover {
                background-color: #e3eaf7;
            }
        }
    }

    .red-dot {
        border: 1px solid transparent;
    }

}

.notification-counter {
    padding: 2px 5px;
    border: 2px solid white;
    display: flex;
    justify-content: center;
    align-items: center;

    &.red-dot {
        color: white;
        box-shadow: unset;
        width: auto;
        height: auto;
        min-width: 21px;
        border-radius: 1rem;
    }

    &__inner {
        position: relative;
        display: flex;
    }

    &__value {
        font-size: 10px !important;
    }
}

.bell {
    display: block;
    width: 40px;
    height: 40px;
    font-size: 40px;
    will-change: transform;

    &.animate {
        animation: ring 2s .7s ease-in-out infinite;
        transform-origin: 50% 4px;
    }
}

@keyframes ring {

    0%,
    43%,
    100% {
        transform: rotate(0deg);
    }

    5% {
        transform: rotate(30deg);
    }

    15% {
        transform: rotate(-30deg);
    }

    25% {
        transform: rotate(20deg);
    }

    35% {
        transform: rotate(-20deg);
    }
}

@keyframes appearDotCounter {
    0% {
        transform: scale(0) translate(50%, -50%);
        opacity: 0;
    }

    100% {
        transform: scale(1) translate(50%, -50%);
        opacity: 1;
    }
}

@keyframes appear {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

// 768px; 16px based font size
@media screen and (max-width: 48em) {
    .notification {
        &__content {
            min-height: 11.5rem;
        }
    }
}