@use '../../../variables' as *;

.tk-employees-leaves {
    position: relative;

    // &__modal-content-update {}
    // &__modal-content-view {}

}

.leave-summary {
    background-color: $primary-lighter-alt-color;
    padding: 1rem;
    border-radius: 10px;
    min-width: 18rem;

    &__content {
        padding: .5rem;
    }

    &__header {
        margin-bottom: .5rem;
    }

    ul {
        display: flex;
        flex-direction: column;
        gap: .3rem;
    }

    &__item {
        display: flex;
        flex-direction: column;
        gap: .3rem;

        &__content {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__subtext {
            margin-left: 1rem;
            display: flex;
            justify-content: space-between;
        }
    }

    .line {
        margin: 1rem 0;
        border-top: 2px solid #d3d3d3;
    }
}