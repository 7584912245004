// 830px; Base: 16px
@media screen and (max-width: 51.875em) {

    .tk-layout-with-logo {
        &__left {
            display: none;
        }

        &__resp-logo-info {
            display: flex;
        }
    }
}