@use '../../variables' as *;

@keyframes growLine {
    from {
        height: 0;
    }

    to {
        height: 80%;
    }
}

$timeline-stale-color: #C8C8C8;
$timeline-positive-color: #10BD4B;
$timeline-negative-color: #D32D2D;
$timeline-waiting-color: #D0C807;
$timeline-canceled-color: #505050;

.timeline {
    position: relative;

    &__inner {
        position: relative;
    }

    &__items {
        position: relative;
        display: flex;
        flex-direction: column;
    }

    &__item {
        display: flex;
        min-height: 5rem;
        gap: 1rem;

        &__status {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;

            &-circle {
                position: relative;
                width: .7rem;
                height: .7rem;
                border-radius: 10rem;
                background-color: $timeline-stale-color;
                display: flex;
            }

            &-line {
                position: relative;
                border-left: 3px solid $timeline-stale-color;
                height: 80%;
                margin: auto;
            }

            &.stale {
                .timeline__item__status-circle {
                    background-color: $timeline-stale-color;
                }

                .timeline__item__status-line {
                    border-left-color: $timeline-stale-color;
                }
            }

            &.positive {
                .timeline__item__status-circle {
                    background-color: $timeline-positive-color;
                }

                .timeline__item__status-line {
                    border-left-color: $timeline-positive-color;
                }
            }

            &.negative {
                .timeline__item__status-circle {
                    background-color: $timeline-negative-color;
                }

                .timeline__item__status-line {
                    border-left-color: $timeline-negative-color;
                }
            }

            &.waiting {
                .timeline__item__status-circle {
                    background-color: $timeline-waiting-color;
                }

                .timeline__item__status-line {
                    border-left-color: $timeline-waiting-color;
                }
            }

            &.canceled {
                .timeline__item__status-circle {
                    background-color: $timeline-canceled-color;
                }

                .timeline__item__status-line {
                    border-left-color: $timeline-canceled-color;
                }
            }
        }

        &__content {
            position: relative;
            padding-bottom: 1rem;
        }
    }
}