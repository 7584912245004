@use '../../variables' as *;

.tk-carousel {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;

    svg {
        opacity: .4;
        cursor: pointer;
        -webkit-transition: -webkit-opacity 200ms ease-in-out;
        -o-transition: -o-opacity 200ms ease-in-out;
        transition: opacity 200ms ease-in-out;

        &:hover {
            opacity: 1;
            -webkit-transition: -webkit-opacity 200ms ease-in-out;
            -o-transition: -o-opacity 200ms ease-in-out;
            transition: opacity 200ms ease-in-out;
        }
    }

    &__container {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
    }

    &__left {
        svg {
            transform: rotate(180deg);
        }
    }

    &__content {
        overflow: hidden;
        min-height: 3rem;
        flex: 1;
    }

    &__items {
        position: relative;
    }

    &__item {
        position: absolute;
        width: 100%;
        -webkit-transition: -webkit-transform .6s ease-in-out;
        -o-transition: -o-transform .6s ease-in-out;
        transition: transform .6s ease-in-out;

        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-perspective: 1000px;
        perspective: 1000px;

        >* {
            height: 100%;
            width: 100%;
        }

        &.left {
            transform: translateX(-100%);
        }

        &.right {
            transform: translateX(100%);
        }
    }

    &__footer {
        display: flex;
    }

    &__dots {
        margin: auto;

        svg {
            width: 1rem;
        }
    }

    &__dot {
        color: $fade-gray;
        -webkit-transition: -webkit-opacity .6s ease-in-out;
        -o-transition: -o-opacity .6s ease-in-out;
        transition: opacity .6s ease-in-out;

        &--active,
        &:hover {
            color: $primary-color;
            opacity: 1 !important;
        }
    }
}