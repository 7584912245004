@use '../../variables' as *;

$controls-margin: 10px;
$controls-height: 40px;
$controls-padding: 0px 17px;
$controls-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
$controls-border-radius: 2px;
$controls-color: #666666;
$controls-color-active: #333333;

.tk-map {
    position: relative;

    &--disabled {
        pointer-events: none;
        cursor: none;
    }

    // target drawing mode controls
    .gmnoprint {
        &:nth-child(4) {
            margin: $controls-margin;
        }

        button[title="Stop drawing"],
        button[title="Draw a shape"] {
            height: $controls-height;
        }
    }

    &-controls {
        background: $white;
        margin: $controls-margin;
        height: $controls-height;
        width: $controls-height;
        box-shadow: $controls-shadow;
        border-radius: $controls-border-radius;
        border: none;

        &.select {
            width: auto;
            padding: 0 .5rem;
        }

        svg {
            cursor: pointer;
            color: $controls-color;

            &:hover {
                color: $controls-color-active;
            }
        }
    }

    &-search {
        margin: $controls-margin;
        height: $controls-height;
        padding: $controls-padding;
        box-shadow: $controls-shadow;
        border-radius: $controls-border-radius;
        border: none;

        &:focus {
            outline: none;
        }
    }

    &-marker {
        cursor: pointer;

        svg {
            color: $primary-color;
        }

        &--hover {
            position: relative;

            .hint {
                position: absolute;
                width: 40;
                height: 40;
                background: white;
            }

            // hover here
            &::after {
                position: absolute;
            }
        }

        &--centered {
            position: relative;

            svg {
                color: $danger-color;
            }
        }
    }

    // customize dialog 
    .gm-style-iw.gm-style-iw-c {
        padding: 0;
        height: 90px;

        .gm-style-iw-chr {
            background: $primary-color;
            color: $white;

            .gm-style-iw-ch {
                display: flex;
                align-items: center;
                padding: 0;
                padding-left: .4rem;
            }

            button {
                width: 24px !important;
                height: 24px !important;

                >* {
                    width: inherit !important;
                    height: inherit !important;
                    background-color: $white !important;
                    margin: 0 !important;
                }
            }
        }

        .gm-style-iw-d {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 1rem;
            margin-left: 1rem;
        }
    }

    .gm-style-iw-ch {
        span {
            font-size: $base-font-size;
            font-weight: 600;
        }
    }
}