@use '../variables' as *;

.tk-dashboard {
    position: relative;
    flex: 1;
    padding: 1rem;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    overflow: auto;
    margin-bottom: 1rem;

    &-company {
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 100%;
        gap: 1rem;

        &__inner {
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            width: 100%;
        }

        &__header {
            position: relative;
        }

        &__content {
            height: 100%;
            width: 100%;
            display: flex;
            gap: 1rem;
        }

        &__left {
            display: flex;
            flex-direction: column;
            width: 70%;
            gap: 1rem;
        }

        &__right {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            width: 30%;
            gap: 1rem;
        }
    }

    &-super {
        padding: 1.25rem;
        background: $white;
        box-shadow: 0px 3px 8px #dddddd;
        border-radius: 15px;
        height: 100%;
        gap: 1rem;

        &__content {
            height: 100%;

            &__inner {
                height: 100%;
                padding: 1rem;
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
                gap: 1.5rem;

                .tk-dashboard-super__title {
                    grid-column: span 1;
                }

                >*:not(.tk-dashboard-super__title) {
                    grid-column: span 1;
                }

                .mini-card {
                    background-color: #f3f2f2;
                    padding: 20px;
                    border-radius: 7px;
                    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
                    transition: transform 0.3s ease;
                    margin: 10px;
                    max-width: 400px;
                    max-height: 200px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: left;
                }

                .mini-card:hover {
                    transform: translateY(-5px);
                }

                .value {
                    font-size: 2.5rem;
                    color: #0052CC;
                    margin-top: 10px;
                    font-weight: 400;
                }

                .title {
                    font-size: 1rem;
                    color: #333;
                    font-weight: 400;
                    margin-top: 10px;
                }


            }
        }

        &__inner {
            flex: 1 1;
            position: relative;
            width: 100%;
            background: $white;
            border-radius: 15px;
            height: 100%;
            // box-shadow: 0px 3px 8px #dddddd;
        }
    }
}

// 1125px; 16px based font size
@media screen and (max-width: 70.3125em) {
    .tk-dashboard-company {
        &__content {
            flex-direction: column;
        }

        &__left {
            width: 100%;
        }

        &__right {
            width: 100%;
        }
    }
}