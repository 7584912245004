@use '../../variables' as *;

.red-dot {
    width: 10px;
    height: 10px;
    background-color: $danger-color;
    border-radius: 50%;
    box-shadow: 0 0 5px $danger-color;

    &.blink {
        animation: #{$kf-blink} 1500ms infinite alternate;
    }
}

.blinking-dot {
    width: 10px;
    height: 10px;
    background-color: $danger-color;
    border-radius: 50%;
    box-shadow: 0 0 5px $danger-color;
    animation: #{$kf-blink} 1500ms infinite alternate;
}