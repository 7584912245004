@use '../../variables' as *;

.tk-banner {
    position: relative;
    width: 100%;
    background-color: transparent;
    display: flex;
    padding: .5rem 1.5rem;
    border-radius: $border-radius;
    font-weight: 600;
    overflow: auto;

    &.info {
        background-color: $primary-lighter-alt-color;
    }

    &.danger {
        background-color: $danger-light-color;
        color: red;
    }

    &.warning {
        background-color: $accent-yellow-color;
        color: $yellow-color;
    }
}