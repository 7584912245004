@use '../../variables' as *;

.tk-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    height: 100%;

    &__right {
        position: relative;
        display: flex;
        align-items: center;
        gap: .5rem;
        margin-left: auto;
        height: 85%;
    }

    .tk-popover {
        &.active {
            .more-icon {
                transform: rotate(180deg);
                transition: $transition-duration transform ease-in-out;
            }
        }

        .tk-header__logo {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .tk-header__logo__content {
            .item {
                cursor: pointer;
                min-width: 6rem;
                display: flex;
                align-items: center;

                &.logout {
                    padding-right: 2px;
                }

                &__icon-wrapper {
                    width: 1.3rem;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }
            }

            .logout {
                svg {
                    opacity: 1;
                }
            }

            button {
                height: auto;
                padding: 0;
                display: flex;
                justify-content: flex-start
            }

            .upgrade {
                font-weight: bold;
                border-radius: 15px;
                width: max-content !important;
                max-width: 8rem;
                max-height: 2rem;
            }
        }

    }

    &__logo {
        position: relative;
        border-radius: 50%;
        transition: $transition-duration opacity ease-in-out;
        border: 2px solid $light-gray;

        &.active,
        &:hover {
            border: 2px solid $primary-color;

            .tk-lettered-avatar {
                opacity: .5;
                transition: $transition-duration opacity ease-in-out;
            }

            .overlay.show {
                opacity: 1;
            }
        }


        .overlay {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: $transition-duration opacity ease-in-out;
            opacity: 0;
            cursor: pointer;

            svg {
                width: 2rem;
                height: auto;
                transition: $transition-duration transform ease-in-out;
            }
        }
    }
}


.header-icons {
    height: 100%;

    &__inner,
    &__list,
    button {
        height: 100%;
    }

    button.tk-custom-btn {
        border-radius: $border-radius-md;
    }

    .mark-as-read-all-icon {
        color: $primary-color;
    }
}

.header-profile {
    position: relative;
    background-color: white;
    border-radius: $border-radius-md;
    max-width: 20rem;
    height: 100%;
    border: 1px solid $dark-gray;

    &__inner {
        display: flex;
        align-items: center;
        padding: 8px 12px;
        gap: .5rem;
        height: 100%;
    }

    &__content {
        overflow: hidden;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

// 768px; 16px based font size
@media screen and (max-width: 48em) {
    .header-profile {
        &__content {
            display: none;
        }
    }
}