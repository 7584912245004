@use '../../variables' as *;

.file-renderer {
    position: relative;

    &__large-image {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.tk-modal.file-renderer-modal {
    .tk-modal__content {
        height: max-content;
    }

    .tk-modal__content__inner {
        max-height: 90vh;
        padding: 0;
        border-radius: $border-radius;

        >div {
            padding: 0;
            margin: 0;
        }
    }
}