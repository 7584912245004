@use '../../variables' as *;

.tk-tag {
    position: relative;
    background-color: $accent-blue-color;
    font-size: $x-small-font-size;
    padding: 5px 7px;
    border-radius: 12px;
    color: $blue-color;
    width: max-content;
    font-weight: 600;
    align-items: center;
    text-transform: uppercase;
    align-items: center;
    display: flex;
    cursor: default;
    border: 1px solid #b5cdfd;
    white-space: nowrap;

    &.solid {
        border: unset;
        color: $white;
        background-color: #246DFF;
        border-color: #246DFF;
        height: 1.3rem;
    }


    &.gray {
        background-color: $gray;
        border: 1px solid #e7e7e7;
        color: $black;
    }

    &.dark-gray {
        background-color: #4D4D4D;
        border: 1px solid #505050;
        color: #D0D0D0;
    }

    &.green {
        color: $dark-green-color;
        background-color: $accent-green-color;
        border: 1px solid #9cf99c;

        &.solid {
            color: $white;
            background-color: $dark-green-color;
            border-color: $dark-green-color;
        }
    }

    &.red {
        color: $danger-color;
        background-color: $danger2-light-color;
        border: 1px solid #ffadad;

        &.solid {
            color: $white;
            background-color: $danger-color;
            border-color: $danger-color;
        }
    }

    &.red-inverse {
        background-color: $danger2-color;
        border: 1px solid $danger2-color;
        color: $white;
    }

    &.yellow {
        color: $dark-yellow-color;
        background-color: $accent-yellow-color;
        border: 1px solid #ffe279;

        &.solid {
            color: #535353;
            background-color: #ffe500;
            border: unset;
        }
    }

    &.brown {
        color: $brown-color;
        background-color: $accent-brown-color;
        border: 1px solid #ffc581;
    }

    &.light-gray {
        &.solid {
            color: $white;
            background-color: #d7d7d7;
        }
    }
}