@use '../variables' as *;

.tk-employees {
    position: relative;

    &__filter-expiry-date {
        input {
            font-size: $small-font-size;
            font-weight: 600;
        }
    }

    &__warning {
        background-color: $danger2-light-color;
        color: $danger-color;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: .5rem 1.5rem;
        margin-top: .5rem;
        border-radius: 15px;
    }

    .new-tag {
        position: absolute;
        top: 1px;
        left: 27px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &__control {
        display: flex;
        justify-content: space-between;
        padding: 1rem 0;
    }

    &__search {
        flex: 1;
        max-width: 35rem;
    }

    &__body {
        margin-top: 1rem;
        height: calc(100vh - 19rem);
    }

    &__item {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: .5rem;

        &__avatar {
            position: relative;
            border: 2px solid #D9D9D9;
            width: 5rem;
            height: auto;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: $transition-duration opacity ease-in-out;
            cursor: pointer;

            .hide {
                opacity: 0;
                transition: $transition-duration opacity ease-in-out;
            }

            &>img {
                width: 100%;
                height: auto;
            }

            &:hover {
                img {
                    filter: blur(2px);
                }

                .hide {
                    opacity: 1;
                    transition: $transition-duration opacity ease-in-out;

                }
            }
        }

        &__name {
            font-size: 12px;
            font-weight: bold;
            max-width: 6rem;
            text-align: center;
        }

        &__designation {
            font-size: 10px;
        }

        &__view {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
        }
    }

    &__modal-content-view {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        flex: 1;

        &__file {
            display: flex;
            justify-content: center;
            max-height: 80vh;
        }

        &__avatar {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            &>div {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: max-content;
                gap: .5rem;
            }

            & .name {
                font-weight: bold;
                text-align: center;
            }

            & .designation {
                font-size: 12px;
                font-weight: 500;
            }
        }

        &__job-desc {
            max-width: 30rem;
            text-align: justify;
            text-indent: 2rem;
        }

        &__top-extra {
            position: relative;
            flex-direction: column;
            background: $background-color;
            border: 1px solid #c2d9ff;
            border-radius: 14px;
            margin-top: 3rem;
            padding-bottom: 1rem;

            .name {
                text-align: center;
                margin: 0 1rem;
            }

            .tags {
                column-gap: 1rem;
                row-gap: .5rem;
            }

            .bottom {
                justify-content: space-around;
            }

            .department-card {
                background: #E4EDFF;
                border: 1px solid #dfe9fd;
                border-radius: 10px;
                padding: 1rem;
                min-width: 20rem;
                max-width: 25rem;
                margin: auto;
            }
        }
    }

    &__modal-content-update {
        &__inner {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            flex: 1;

            &-row {
                display: flex;
                flex-wrap: wrap;
                gap: 2rem;
                justify-content: space-between;

                &>div {
                    flex: 1;
                }
            }
        }

        .tk-input {
            overflow: unset;
        }

        &__group {
            &__header {
                margin-bottom: 1rem;
            }
        }

        .avatar.medium {
            width: 2.5rem !important;
            height: 2.5rem !important;
        }
    }
}

.insurance-mini-card {
    position: relative;
    background: $background-color;
    padding: 1rem;
    border-radius: 15px;
    overflow: hidden;
    height: 5rem;

    &__inner {
        display: flex;
        height: 100%;
        align-items: center;
        gap: 1rem;
    }

    &__divider {
        height: 100%;
        width: 2px;
        background: $light-gray;
    }

    &__title {
        font-weight: 600;
        font-size: 1.1em;

        &.status {
            opacity: .8;
        }
    }

    &__left {
        display: flex;
        flex-direction: column;
        gap: .3rem;
        min-width: 12rem;
    }

    &__right {
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    &__action {
        margin-left: auto;
    }
}