@use '../../variables' as *;

.tk-card-grid {
    display: flex;
    flex-direction: column;
    background-color: $background-color;
    border-radius: $border-radius;
    overflow: hidden;
    max-width: 25rem;
    box-shadow: $box-shadow;

    &__inner {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        border-radius: $border-radius;
        border-radius: $border-radius;
    }

    &__header {
        padding: .5rem 1.5rem;
        background-color: $primary-color;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;

        .edit {
            width: 1.2rem;
            position: absolute;
            right: 2rem;
        }

        svg {
            width: 100%;
        }
    }

    &__content {
        background-color: transparent;
        display: flex;
        padding: 1rem;
    }

    &__footer {
        position: relative;
        padding: 1rem;
    }

    .tk-hr {
        padding: 0 .5rem;

        hr {
            border-color: $primary-bright-color !important;
            margin: 0;
        }
    }
}