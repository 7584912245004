@use '../../variables' as *;

.tk-editor {
    width: 100%;
    display: flex;
    flex-direction: column;
    // .controls {
    //     display: flex;
    //     border: 1px solid #ccc;
    //     border-top: 0;
    //     padding: 10px;
    // }

    // .controls-right {
    //     margin-left: auto;
    // }

    // .state {
    //     margin: 10px 0;
    //     font-family: monospace;
    // }

    // .state-title {
    //     color: #999;
    //     text-transform: uppercase;
    // }

    .ql-container {
        overflow: hidden;
    }

    .ql-editor {
        background-color: $white;
        height: 14rem;
    }
}

.tk-view-editor {
    strong {
        color: black;
    }

    ul {
        margin: revert;
        padding: revert;
    }

    li {
        margin: revert;
        padding: revert;
        list-style: revert;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        margin: revert;
        padding: revert;
    }
}