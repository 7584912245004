@use '../../variables' as *;

.tk-signup {
    position: relative;
    margin-top: 2rem;

    &__subtext {
        margin-top: 1rem;
    }

    &__footer {
        position: relative;
        font-size: 14px;
        margin-top: 2rem;
        margin-bottom: 2rem;

        &>a {
            &:hover {
                text-decoration: underline;
            }
        }

    }

    &__terms {
        flex: 70%;
        display: flex;
        font-size: 12px;
        gap: .5rem;

        &>span {
            text-align: left;
        }
    }

    button {
        flex: 30%;
        width: min-content;
    }

    .tk-input__upload__file {
        margin-top: 0;
    }
}