@use '../variables' as *;

.tk-settings {
    position: relative;

    &__body {
        padding-top: 1.5rem;

        & .tk-tabs__item {

            &>form,
            &>div {
                position: relative;
                max-width: 60vw;
                margin: auto;
            }
        }
    }

    .work-tab {
        position: relative;
    }

    .tk-card-page {
        &__inner {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}

.department-settings,
.designation-settings {
    min-height: 30rem;
}

.salary-settings {
    .mini-guide {
        text-align: center;
        margin-top: 0;
    }
}

.tk-settings-setup {
    position: relative;
    height: 100%;
    overflow: auto;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    &__title {
        text-align: center;
        margin-bottom: 1rem;
    }

    &__steps {
        justify-content: center;
    }

    &__top-ctrls {
        position: relative;

        .steps-container {
            display: flex;
            justify-content: center;
        }
    }

    &__controls {
        margin: auto;
        margin-top: 2rem;
    }

    .wrapper {
        width: 70vw;
        margin: auto;
        background: $white;
        padding: 2rem 1rem;
        border-radius: $border-radius;
    }

    &__header {
        p {
            text-align: center;
            max-width: 45rem;
        }

        &-info {
            max-width: 50vw;
            min-width: 50%;
            margin: auto;
        }
    }

    &__content {
        position: relative;
        min-width: 30vw;
        margin-top: 2rem;

        &>div {
            max-width: 50vw;
            min-width: 50%;
            margin: auto;
        }
    }
}