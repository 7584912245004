@use '../../variables' as *;

/* 
* Styles for screens 770px and below responsible for font size responsiveness
*/
@media screen and (max-width: 48.125em) {
    body {
        font-size: $base-font-size-sm;
    }

    h1 {
        font-size: $h1-font-size-sm;
    }

    h2 {
        font-size: $h2-font-size-sm;
    }

    h3 {
        font-size: $h3-font-size-sm;
    }

    h4 {
        font-size: $h4-font-size-sm;
    }

    h5 {
        font-size: $h5-font-size-sm;
    }

    h6 {
        font-size: $h6-font-size-sm;
    }

    .xs-font {
        font-size: $x-small-font-size-sm;
    }

    .small-font {
        font-size: $small-font-size-sm;
    }

    .base-font {
        font-size: $base-font-size-sm;
    }

    .normal-font {
        font-size: $normal-font-size-sm;
    }

    .large-font {
        font-size: $large-font-size-sm;
    }
}