.tk-signin {
    position: relative;

    &__footer {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        gap: 1rem;

        &__top {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__signup {
            margin-top: 1rem;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__remember-me {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .5rem;
    }

    &__forgot-password {
        &:hover {
            text-decoration: underline;
        }
    }
}