@use '../../variables' as *;

$lineSize: 4px;
$radius: 20px;
$sliderSize: 200px;
$thumbSize: 24px;

.slider {
    position: relative;
    background: $scroll-body-color;
    cursor: pointer;
    display: flex;
    align-items: center;
    user-select: none;
    transition: background 0.3s ease;
    border-radius: $radius;

    &.horizontal {
        width: $sliderSize;
        height: $lineSize;
    }

    &.vertical {
        width: $lineSize;
        height: $sliderSize;
    }

    .slider-line {
        position: absolute;
        background: $primary-color;
        bottom: 0;
        left: 0;
        transition: height 0.1s linear, width 0.1s linear;
        border-radius: $radius;

        &__inner {
            position: relative;
            height: 100%;

            .slider-thumb {
                position: absolute;
                border: 2px solid $primary-color;
                background-color: white;
                color: $primary-color;
                width: $thumbSize;
                height: $thumbSize;
                border-radius: 50%;
                top: -10px;
                transform: translateX(-50%);
                left: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

}