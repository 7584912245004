.tk-not-found {
    position: relative;
    width: 100vw;
    height: 100vh;

    .btn-home {
        max-width: max-content;
        background: #0052cc;
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        top: 90%;
    }
}